import { ElementRef, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MapsResponseInterface } from '../models/maps-response.model';
import { Forms } from '../Tools/forms';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  private googleMapsKey = environment.GOOGLE_MAPS_KEY;

  constructor(
    private http: HttpClient
  ) {
  }

  async initMap(mapElementId: string, args?: any): Promise<void> {
    // @ts-ignore
    let map: google.maps.Map;

    // @ts-ignore
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    map = new Map(document.getElementById(mapElementId) as HTMLElement, args);
    return map;
  }

  public getDataFromMarker(position: { lat: string, lng: string }): Observable<HttpResponse<MapsResponseInterface>> {
    return this.http.get<MapsResponseInterface>('https://maps.googleapis.com/maps/api/geocode/json', {
      observe: 'response',
      params: {
        latlng: position.lat + ',' + position.lng,
        key: this.googleMapsKey
      }
    });
  }

  public getDataFromAddress(address: string): Observable<HttpResponse<MapsResponseInterface>> {
    return this.http.get<MapsResponseInterface>('https://maps.googleapis.com/maps/api/geocode/json', {
      observe: 'response',
      params: {
        address,
        key: this.googleMapsKey
      }
    });
  }

}
