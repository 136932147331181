import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as copy from 'copy-to-clipboard';

export class Forms {
  public static dateTimeFormat = 'dd/MM/yyyy hh:mm a';

  public static handleData(response: HttpResponse<any>, status: number): boolean {
    return response.status === status && response.body !== undefined && response.body !== null;
  }

  public static handleError(response: HttpResponse<any>, spinner?: NgxSpinnerService): void {
    spinner?.hide();
    console.log(response);
    Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un error',
      text: 'HTTP Code ' + response.status
    });
  }

  public static getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[ key ] === value);
  }

  public static keepOriginalOrder(): number { // key value angular pipe
    return 0;
  }

  public static copyToClipboard(content: string): void {
    copy(content);
  }
}
