// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /* Local environment */
  /*SERVICES_URL: 'http://localhost:3000/api/',
  WS_ENDPOINT: 'ws://localhost:3000',*/

  /* Remote environment */
  //  SERVICES_URL: 'https://d5f3-2806-261-2402-9d80-6d5f-3f4f-f6e5-b1a8.ngrok.io/api/',
  SERVICES_URL: 'https://api-develop.coyotefoodsapp.com/api/',
  WS_ENDPOINT: 'wss://api-develop.coyotefoodsapp.com',

  GOOGLE_MAPS_KEY: 'AIzaSyBrR8NfCnH5Lilu1-SKkVt7Uw5xSftsYt4',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
