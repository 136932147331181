import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { delay, retry, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private webSocketSubject: WebSocketSubject<any>;

  socketSubscribers = {
    dashboardSubscription: new Subscription(),
    orders: new Subscription()
  };

  serviceToReload: Subject<string>;

  constructor() {
    this.serviceToReload = new Subject<any>();
  }

  public connect(): void {
    console.log('[SOCKET][CONNECTING]');

    this.webSocketSubject = webSocket(environment.WS_ENDPOINT);

    this.webSocketSubject
      .pipe(
        retryWhen(errors => errors.pipe(delay(5000)))
      ).subscribe((socketResponse) => {
      console.log('[SOCKET][DATA]', socketResponse);
      this.serviceToReload.next(socketResponse.service);
    }, (socketError) => { // called if at any point WebSocket API signals some kind of error
      console.log(socketError);
    }, () => { // called when connection is closed (for whatever reason)
      console.log('[SOCKET][COMPLETED]');
    });
  }

  public close() {
    this.webSocketSubject.complete();
  }
}
