import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { UserService } from "../../../../shared/services/user.service";
import Swal from "sweetalert2";
import { AuthRouteNames } from "../auth-route.names";
@Component({
  selector: "app-recover-password-users",
  templateUrl: "./recover-password-users.component.html",
  styleUrls: ["./recover-password-users.component.scss"],
})
export class RecoverPasswordUsersComponent implements OnInit {
  public readonly AuthRouteNames = AuthRouteNames;
  recoverPasswordForm = this.formBuilder.group({
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(
          "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
        ),
      ],
    ],
  });
  error: string;
  login: boolean;
  loginAlert: boolean;
  sendMessage: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit(): void {}

  recoverPassword() {
    this.userService.sendUserPasswordMessage(this.emailControl.value).subscribe(
      () => {
        this.sendMessage = true;
      },
      (error) => {
        if (
          error["error"]["errors"]["message"] ===
          "Cannot read property 'updatedAt' of null  error send"
        ) {
          this.loginAlert = true;
        } else if (
          error["error"]["errors"]["message"] ===
          "¡Error! Tiene que pasar 15 para mandar un nuevo correo."
        ) {
          Swal.fire({
            icon: "error",
            title: "¡Opps ha ocurrido un error!",
            text: "Debes esperar 15 minutos para enviar un nuevo correo de recuperación de contraseña.",
          });
        } else {
          this.loginAlert = true;
        }
      },
    );
  }

  SetLoginTrue() {
    this.loginAlert = false;
  }

  setSendMessageFalse() {
    this.recoverPasswordForm.reset();
    this.sendMessage = false;
  }

  get emailControl() {
    return this.recoverPasswordForm.get("email") as FormControl;
  }
}
