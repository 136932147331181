import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RecoverPasswordUsersComponent } from "./recover-password-users/recover-password-users.component";
import { UpdatePasswordUsersComponent } from "./update-password-users/update-password-users.component";
import { AuthRouteNames } from "./auth-route.names";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: LoginComponent,
  },
  {
    path: AuthRouteNames.RECUPERACION,
    component: RecoverPasswordUsersComponent,
  },
  {
    path: AuthRouteNames.RECUPERACION_TOKEN,
    component: UpdatePasswordUsersComponent,
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
