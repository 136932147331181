import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReviewsBranchOfficesService {

  endpoint = environment.SERVICES_URL + 'reviews-branch-offices';

  constructor(
    private http: HttpClient
  ) {
  }

  // tslint:disable-next-line:variable-name max-line-length
  public getReviewsByIdCity(id_city: string, page: string, review_id?: string, branch_office_id?: string, value?: string, group_branch_offices?: string): Observable<HttpResponse<ReviewsBranchOfficesService>> {
    return this.http.get<ReviewsBranchOfficesService>(`${ this.endpoint }/${ id_city }`, {
      observe: 'response',
      params: {
        page,
        review_id,
        branch_office_id,
        value,
        group_branch_offices
      }
    });
  }

}
