<div class="page login-page">
  <div class="container d-flex align-items-center">
    <div class="form-holder has-shadow">
      <div
        *ngIf="loginAlert === true"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        <strong>¡La contraseña no es igual!</strong> Verifica de nuevo los
        datos.
        <button
          type="button"
          class="close"
          (click)="SetLoginTrue()"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="row">
        <!-- Logo & Information Panel-->
        <div class="col-lg-6">
          <div class="info d-flex align-items-center">
            <div class="content text-center logo-center">
              <img src="assets/img/componente_icono.svg" style="width: 80%" />
              <div class="logo">
                <h1 class="title-1">COYOTE FOODS</h1>
              </div>
              <p class="subtitle-1">Transportamos sabor</p>
            </div>
          </div>
        </div>
        <!-- Form Panel    -->
        <div class="col-lg-6 bg-white">
          <div class="form d-flex align-items-center">
            <div class="content">
              <div style="padding-bottom: 10%">
                <h5 class="input-material text">Cambiar contraseña.</h5>
              </div>
              <form
                method="post"
                class="form-validate"
                [formGroup]="recoverPasswordForm"
              >
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="login-username" class="label-material input"
                        >Contraseña</label
                      >
                      <input
                        id="login-username"
                        [formControl]="this.passwordControl"
                        [ngClass]="{
                          'box-border-red':
                            this.passwordControl.invalid &&
                            this.passwordControl.touched
                        }"
                        type="password"
                        name="loginUsername"
                        required
                        class="input-material input"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="login-username" class="label-material input"
                        >Confirmar contraseña</label
                      >
                      <input
                        [formControl]="this.repeatedPasswordControl"
                        [ngClass]="{
                          'box-border-red':
                            repeatedPasswordControl.invalid &&
                            repeatedPasswordControl.touched
                        }"
                        type="password"
                        name="loginUsername"
                        required
                        class="input-material input"
                      />
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-primary Quick-Bold d-block m-auto"
                  [disabled]="
                    this.passwordControl.invalid ||
                    repeatedPasswordControl.invalid
                  "
                  (click)="updatePassword()"
                >
                  Actualizar contraseña
                </button>
                <!-- This should be submit button but I replaced it with <a> for demo purposes-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyrights text-center">
    <p>
      Designed by
      <img
        src="assets/img/logo-white-3.png"
        href="https://itelisoft.com/"
        style="width: 100px; margin-left: 5px"
        class="external"
        alt="Itelisoft logo"
      />
    </p>
  </div>
</div>
