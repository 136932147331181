import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../../shared/services/user.service";
import { CoreRouteNames } from "../core-route.names";
import { AdminSucursalRouteNames } from "../../modules/admin-sucursal/admin-sucursal-route.names";

@Injectable({
  providedIn: "root",
})
export class SuperAdminGuard implements CanActivateChild {
  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userService.isLoggedIn()) {
      if (this.userService.isSuperAdmin) return true;

      this.router.navigate([
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.PEDIDOS,
      ]);
    }
    return false;
  }
}
