import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as environment } from '../../../environments/environment';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    token: string;
    urlService = environment.SERVICES_URL;
    base64: any;

    constructor(public http: HttpClient,
    ) {
        this.token = sessionStorage.getItem('token');

    }

    public static generateId(): number {
        return Math.floor(10000000000 + Math.random() * 90000000000);
    }


    /*  uploadFileUsers(file, idUser) {

          const contentType = file.type;

          const params = {
              Bucket: 'merkadote',
              Key: 'users/' + idUser.replace(/ /g, '') + '/' + file.name.replace(/ /g, ''),
              Body: file,
              ACL: 'public-read',
              ContentType: contentType
          };
          this.bucket.upload(params, function (err, data) {
              if (err) {
                  //  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              // console.log('Successfully uploaded file.', data);
              return true;
          });
          //for upload progress
          this.bucket.upload(params).on('httpUploadProgress', function (evt) {
              // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  // console.log('There was an error uploading your file: ', err);
                  return false;
              }
              // console.log('Successfully uploaded file.', data);
              return true;
          });
      }
  */

    // tslint:disable-next-line:variable-name
    uploadUserFiles(id_user: string, images: Array<{ file: File, localUrl: (string | ArrayBuffer) }>) {
        const formData = new FormData();
        images.forEach(image => {
            if (image.localUrl) {
                formData.append(
                  `/users/${ id_user }`, // directory inside of bucket
                  image.file,
                  UploadService.generateId().toString()
                );
            }
        });
        // tslint:disable-next-line:max-line-length
        return this.http.put(this.urlService + 'aws/files', formData, {
            observe: 'response'
        });
    }

    uploadFileUsers(file, name, id, base64) {
        let request;
        let httpHeaders = new HttpHeaders();
        const fileSpace = file.name.replace(/ /g, '').replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,"");
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/file', {
            "id": "users",
            "name_folder": name.replace(/\s/g, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,""),
            "img_name": fileSpace.replace(/\.[^/.]+$/, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,"") + id,
            "base64ImageData": base64


        }, options);
    }

    /*uploadFileProduct(file, id, idBranch, idItem, fileConverted) {
        let request;
        let httpHeaders = new HttpHeaders();
        const fileSpace = file.name.replace(/ /g, '');
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/file', {
            "id": "products/" + idBranch,
            "name_folder": idItem.replace(/\s/g, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*!/g,""),
            "img_name": fileSpace.replace(/\.[^/.]+$/, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*!/g,"") + id,
            "base64ImageData": fileConverted


        }, options);
    }*/

    // tslint:disable-next-line:variable-name max-line-length
    uploadProductFiles(id_branch_office: string, id_product: string, images: Array<{ file: File, localUrl: (string | ArrayBuffer) }>): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const imagesToUpload = images.filter(image => image.localUrl).length;
            if (imagesToUpload === 0) {
                resolve(undefined);
            }
            let s3Urls: { url: string }[] = [];
            let fileCounter = 0;
            const formData = new FormData();
            for (const image of images) {
                if (image.localUrl) {
                    formData.set(
                      `/products/${ id_branch_office }/${ id_product }`, // directory inside of bucket
                      image.file,
                      UploadService.generateId().toString()
                    );
                    const s3Response = await this.http.put<any>(this.urlService + 'aws/files', formData, {
                        observe: 'response'
                    }).toPromise();
                    s3Urls = s3Urls.concat(s3Response.body?.s3Urls);
                    fileCounter++;

                    if (fileCounter === imagesToUpload) {
                        resolve(s3Urls);
                    }
                }
            }
        });
    }

    uploadPromotionsFiles(id_promotion: string, image: { file: File, localUrl: (string | ArrayBuffer) }): Observable<{ s3Urls: { url: string }[], filesSuccessCount: number, filesFailureCount: number }> {
        const formData = new FormData();
        formData.append(
          `/promotions/${ id_promotion }`, // directory inside of bucket
          image.file,
          UploadService.generateId().toString()
        );
        return this.http.put<{ s3Urls: { url: string }[], filesSuccessCount: number, filesFailureCount: number }>(this.urlService + 'aws/files', formData);
    }

    deleteFiles(s3Url: Array<{ s3Url: string }>) {
        return this.http.request('delete', this.urlService + 'aws/files', {
            observe: 'response',
            body: s3Url
        });
    }




    deleteFileProducts(data) {
        let route = data.url.replace('https://merkadote.s3-us-west-2.amazonaws.com/', "");
        let name = route.split("/").reverse()[0];
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/delete', {
            "url": route.replace('/' + name, ""),
            "img_name": name,



        }, options);
    }

    deleteFile(url) {
        let route = url.replace('https://merkadote.s3-us-west-2.amazonaws.com/', "");
        let name = route.split("/").reverse()[0];
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/delete', {
            "url": route.replace('/' + name, ""),
            "img_name": name,



        }, options);
    }

    /*  deleteFile(url) {
          const params = {
              Bucket: 'merkadote',
              Key: url.replace('https://merkadote.s3-us-west-2.amazonaws.com/', ""),
          };

          this.bucket.deleteObject(params, function (err, data) {
              if (err) {
                  console.log('There was an error deleting your file: ', err.message);
                  return;
              }
              sessionStorage.setItem("status", "true");
              console.log('Successfully deleted file.');
          });
      }*/


    updloadMarketFile(file, name, id, base64) {

        let request;
        let httpHeaders = new HttpHeaders();
        const fileSpace = file.name.replace(/ /g, '');
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/file', {
            "id": "markets",
            "name_folder": name.replace(/\s/g, ""),
            "img_name": fileSpace.replace(/\.[^/.]+$/, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,"") + id,
            "base64ImageData": base64,


        }, options);
    }

    // tslint:disable-next-line:variable-name
    uploadMarketFiles(id_branch_office: string, images: Array<{ file: File, localUrl: (string | ArrayBuffer) }>) {
        const formData = new FormData();
        images.forEach(image => {
            if (image.localUrl) {
                formData.append(
                  `/markets/${ id_branch_office }`, // directory inside of bucket
                  image.file,
                  UploadService.generateId().toString()
                );
            }
        });
        // tslint:disable-next-line:max-line-length
        return this.http.put(this.urlService + 'aws/files', formData, {
            observe: 'response'
        });
    }

    uploadDealerFile(dealerId: string, image: { file: File, localUrl: (string | ArrayBuffer) }) {
        const formData = new FormData();
        if (image.localUrl) {
            formData.append(
              `/dealers/${ dealerId }`, // directory inside of bucket
              image.file,
              UploadService.generateId().toString()
            );
        }
        // tslint:disable-next-line:max-line-length
        return this.http.put(this.urlService + 'aws/files', formData, {
            observe: 'response'
        });
    }

    /*   updloadMarketFile(file, idItem) {

           const contentType = file.type;
           const bucket = new S3(
               {
                   accessKeyId: 'AKIAWP5SA6GSUHIUCYRW',
                   secretAccessKey: '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',
                   region: 'us-west-2'
               }
           );
           const params = {
               Bucket: 'merkadote',
               Key: "markets/" + idItem.replace(/ /g, '') + '/' + file.name.replace(/ /g, ''),
               Body: file,
               ACL: 'public-read',
               ContentType: contentType
           };
           bucket.upload(params, function (err, data) {
               if (err) {
                   // console.log('There was an error uploading your file: ', err);
                   return false;
               }
               // console.log('Successfully uploaded file.', data);

               return true;
           });
           //for upload progress
           bucket.upload(params).on('httpUploadProgress', function (evt) {
               // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
           }).send(function (err, data) {
               if (err) {
                   // console.log('There was an error uploading your file: ', err);
                   return false;
               }
               //   console.log('Successfully uploaded file.', data);


               return true;

           });
       }*/


    updateMarketFile(file, idBranch, idItem) {
        const contentType = file.type;
        const bucket = new S3(
            {
                accessKeyId: 'AKIAWP5SA6GSUHIUCYRW',
                secretAccessKey: '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',
                region: 'us-west-2'
            }
        );
        const params = {
            Bucket: 'merkadote',
            Key: "markets/" + idBranch.replace(/ /g, '') + '/' + idItem.replace(/ /g, '') + '/' + file.name.replace(/ /g, ''),
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };
        bucket.upload(params, function (err, data) {
            if (err) {
                // console.log('There was an error uploading your file: ', err);
                return false;
            }
            // console.log('Successfully uploaded file.', data);

            return true;
        });
        // for upload progress
        bucket.upload(params).on('httpUploadProgress', function (evt) {
            // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
        }).send(function (err, data) {
            if (err) {
                //  console.log('There was an error uploading your file: ', err);
                return false;
            }
            //  console.log('Successfully uploaded file.', data);


            return true;

        });

    }

    uploadFileDealer(file, idBranch, name, base64, id) {

        let request;
        let httpHeaders = new HttpHeaders();
        const fileSpace = file.name.replace(/ /g, '');
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/file', {
            "id": "dealers/" + idBranch,
            "name_folder": name.replace(/\s/g, ""),
            "img_name": fileSpace.replace(/\.[^/.]+$/, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,"") + id,
            "base64ImageData": base64,


        }, options);
    }

    /* uploadFileDealer(file, idBranch, idItem) {
         const contentType = file.type;
         const bucket = new S3(
             {
                 accessKeyId: 'AKIAWP5SA6GSUHIUCYRW',
                 secretAccessKey: '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',
                 region: 'us-west-2'
             }
         );
         const params = {
             Bucket: 'merkadote',
             Key: "dealers/" + idBranch.replace(/ /g, '') + '/' + idItem.replace(/ /g, '') + '/' + file.name.replace(/ /g, ''),
             Body: file,
             ACL: 'public-read',
             ContentType: contentType
         };
         bucket.upload(params, function (err, data) {
             if (err) {
                 //  console.log('There was an error uploading your file: ', err);
                 return false;
             }
             //  console.log('Successfully uploaded file.', data);

             return true;
         });
         //for upload progress
         bucket.upload(params).on('httpUploadProgress', function (evt) {
             // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
         }).send(function (err, data) {
             if (err) {
                 //  console.log('There was an error uploading your file: ', err);
                 return false;
             }
             //  console.log('Successfully uploaded file.', data);


             return true;

         });

     }*/


     updloadCategoryImage(file, name_folder, base64, id) {

        let request;
        let httpHeaders = new HttpHeaders();
        const fileSpace = file.name.replace(/ /g, '');
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.urlService + 'aws/file', {
            "id": "generalcategories",
            "name_folder":  name_folder.replace(/\s/g, ""),
            "img_name": fileSpace.replace(/\.[^/.]+$/, "").replace(/\$|\#|\%|\(|\)|\{|\}|\[|\]|\&|\+|\-|\*/g,"")+id ,
            "base64ImageData": base64,
        }, options);
    }

    // tslint:disable-next-line:variable-name
    uploadGeneralCategoryFile(image: { file: File, localUrl: (string | ArrayBuffer) }) {
        const formData = new FormData();
        formData.append(
          `/generalcategories`, // directory inside of bucket
          image.file,
          UploadService.generateId().toString()
        );
        // tslint:disable-next-line:max-line-length
        return this.http.put<{ filesFailureCount: number, filesSuccessCount: number, s3Urls: [ { url: string } ] }>(this.urlService + 'aws/files', formData, {
            observe: 'response'
        });
    }


}
