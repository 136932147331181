import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ModalCitieService } from '../shared/services/modal-citie.service';
import { CitiesService } from '../shared/services/cities.service';
import { SocketService } from '../shared/services/socket.service';
import { CitiesModel } from '../shared/models/cities.model';
import { UserService } from '../shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  audio = new Audio('/assets/sounds/ringtones-heart.mp3');
  currentCityId = localStorage.getItem('currentCityId');
  cityName: string;
  arrayCities: CitiesModel[] = [];

  constructor(
    private modalService: ModalCitieService,
    private citiesService: CitiesService,
    private socket: SocketService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.modalService.updateCitie(this.currentCityId); // guardar ciudad en local storage

    this.socket.connect(); // socket de pedido nuevo

    if (this.userService.isSuperAdmin) {
      this.getCities();
    }
  }

  getCities() {
    this.spinner.show();
    const storageCityName = localStorage.getItem('currentCity');
    if (storageCityName) {
      this.cityName = storageCityName;
    }

    this.citiesService.getCities().subscribe(
      (res: Array<CitiesModel>) => {
        this.arrayCities = res;

        if (sessionStorage.getItem('id_city') === '6153df3ec14badc7f78362c7') {
          // es super-admin de la piedad, permitir seleccionar ciudades
          if (this.cityName) {
            this.modalService.updateCitie(this.currentCityId, this.cityName);
            return;
          }
          this.modalService.show(); // sin ciudad en local storage, mostrar modal
          return;
        }

        // si no es super-admin de la piedad, seleccionar la ciudad donde fue creado el usuario
        this.arrayCities.filter((city) => {
          if (city._id === this.currentCityId) {
            this.cityName = city.city;
            this.modalService.updateCitie(this.currentCityId, this.cityName);
          }
        });
      },
      (error) => console.log(error, ' error'),
      () => this.spinner.hide(),
    );
  }

  ngOnDestroy() {
    this.socket.close();
  }

  hideModal() {
    this.modalService.hide();
  }

  setValue(e) {
    this.currentCityId = e.target.value;
  }

  save() {
    const citySelected: CitiesModel[] = this.arrayCities.filter((c) => {
      return c._id === this.currentCityId;
    });
    this.modalService.updateCitie(citySelected[0]._id, citySelected[0].city);
    this.hideModal();
  }

  probarSonido() {
    this.audio.play();
    this.audio.loop = true;
    Swal.fire({
      icon: 'info',
      title: 'Reproduciendo',
      // text: 'Se está reproduciendo el sonido de prueba.',
      timerProgressBar: true,
      timer: 4000,
    }).then(() => {
      this.audio.pause();
    });
  }
}
