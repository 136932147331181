import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CoreComponent } from "./core.component";
import { AuthGuard } from "./guards/auth.guard";
import { PublicGuard } from "./guards/public.guard";
import { CoreRouteNames } from "./core-route.names";
import { SuperAdminGuard } from "./guards/super-admin.guard";
import { AdminSucursalGuard } from "./guards/admin-sucursal.guard";
import { SuperAdminRouteNames } from "../modules/super-admin/super-admin-route.names";

const routes: Routes = [
  {
    path: "",
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo:
          CoreRouteNames.SUPER_ADMIN + "/" + SuperAdminRouteNames.PEDIDOS,
      },
      {
        path: CoreRouteNames.SUPER_ADMIN,
        canActivateChild: [SuperAdminGuard],
        loadChildren: () =>
          import("../modules/super-admin/super-admin.module").then(
            (m) => m.SuperAdminModule,
          ),
      },
      {
        path: CoreRouteNames.ADMIN_SUCURSAL,
        canActivateChild: [AdminSucursalGuard],
        loadChildren: () =>
          import("../modules/admin-sucursal/admin-sucursal.module").then(
            (m) => m.AdminSucursalModule,
          ),
      },
    ],
  },
  {
    path: CoreRouteNames.INICIAR_SESION,
    canActivate: [PublicGuard],
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "**", redirectTo: CoreRouteNames.INICIAR_SESION },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
