import { Component, OnInit } from '@angular/core';
import { ModalCitieService } from 'src/app/shared/services/modal-citie.service';
import { Router } from '@angular/router';
import { SuperAdminRouteNames } from "../../../modules/super-admin/super-admin-route.names";
import { AdminSucursalRouteNames } from "../../../modules/admin-sucursal/admin-sucursal-route.names";
import { CoreRouteNames } from "../../core-route.names";
import { DealerRouteNames } from '../../../shared/modules/dealer/dealer-route.names';
import { FinanceRouteNames } from '../../../shared/modules/finance/finance-route.names';
import { CouponRouteNames } from 'src/app/shared/modules/coupon/coupon-route.names';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ]
})
export class SidebarComponent implements OnInit {
  public readonly CoreRouteNames = CoreRouteNames;
  public readonly SuperAdminRouteNames = SuperAdminRouteNames;
  public readonly AdminSucursalRouteNames = AdminSucursalRouteNames;
  public readonly DealerRouteNames = DealerRouteNames;
  public readonly FinanceRouteNames = FinanceRouteNames;
  public readonly CouponRouteNames = CouponRouteNames;


  isSuperAdminLaPiedad = window.sessionStorage.getItem('id_city') === '6153df3ec14badc7f78362c7';
  userRole: string;

  isOrdersSelected = true;

  isCouponsSelected = false;
  isDealersSelected = false;
  isFinanceSelected = false;
  /*private couponsSubRoutesList = [
    '/pages/coupons',
    '/pages/coupons-redeemed',
  ];*/

  constructor(
    public router: Router,
    public modalService: ModalCitieService
  ) {
  }

  ngOnInit(): void {
    this.userRole = window.sessionStorage.getItem('role');

    /*for (const route of this.couponsSubRoutesList) {
      if (this.router.url === route) {
        this.isCouponsSelected = true;
        break;
      }
    }*/
  }

  openSelectCityModal() {
    this.modalService.show();
  }
}
