import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { RecoverPasswordUsersComponent } from "./recover-password-users/recover-password-users.component";
import { UpdatePasswordUsersComponent } from "./update-password-users/update-password-users.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { SharedModule } from "../../../shared/shared.module";
import { AuthComponent } from "./auth.component";

@NgModule({
  declarations: [
    LoginComponent,
    RecoverPasswordUsersComponent,
    UpdatePasswordUsersComponent,
    AuthComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, SharedModule],
})
export class AuthModule {}
