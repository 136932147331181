import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CoreRoutingModule } from "./core-routing.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpTokenInterceptor } from "./interceptors/http-token.interceptor";
import {
  FooterComponent,
  NavbarComponent,
  SidebarComponent,
} from "./components";
import { CoreComponent } from "./core.component";
import { AuthModule } from "./modules/auth/auth.module";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    CoreComponent,
  ],
  imports: [ CommonModule, CoreRoutingModule, AuthModule, SharedModule ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
  ],
  exports: [CoreComponent],
})
export class CoreModule {}
