import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment as environment } from '../../../environments/environment';
import { BranchOfficeInterface, BranchOfficeModel } from 'src/app/shared/models/branchOfficeModel';
import { CategoriesModel } from '../models/categories.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SucursalesService {
    token;
    IdBranch: string;
    cityId: any;

    constructor(public http: HttpClient) {
        this.token = sessionStorage.getItem('token');
        this.IdBranch = sessionStorage.getItem('id_branch_office');


    }

    create(dato: any, arraySchedule: any) {

        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(environment.SERVICES_URL + 'branch_offices', {
            'name': dato.name.trim(),
            'address': dato.address.trim(),
            'email': dato.email.trim(),
            'phone': dato.phone,
            'schedule': arraySchedule,
            'postal_code': dato.pc,
            'city': dato.city.trim(),
            'state': dato.state.trim(),
            'commission': dato.comission,
            'allow_card_payment': dato.allow_card_payment,
            'latitude': dato.latitude,
            'length': dato.length,
            'image': dato.image.replace(/ /g, ''),
            "id_general_categories": dato.id_general_categories,
            "estatus": dato.estatus,
            "id_city": dato.id_city
        }, options);
    }

    createBranchOffice(branchOfficeModel: BranchOfficeModel) {
        return this.http.post(`${ environment.SERVICES_URL }branch_offices`, branchOfficeModel, {
            observe: 'response'
        });

        /*        return request = this.http.post(environment.SERVICES_URL + 'branch_offices', {
                    'name': dato.name.trim(),
                    'address': dato.address.trim(),
                    'email': dato.email.trim(),
                    'phone': dato.phone,
                    'schedule': arraySchedule,
                    'postal_code': dato.pc,
                    'city': dato.city.trim(),
                    'state': dato.state.trim(),
                    'commission': dato.comission,
                    'allow_card_payment': dato.allow_card_payment,
                    'latitude': dato.latitude,
                    'length': dato.length,
                    'image': dato.image.replace(/ /g, ''),
                    "id_general_categories": dato.id_general_categories,
                    "estatus": dato.estatus,
                    "id_city": dato.id_city
                }, options);*/
    }

    getCategoriasIdBranch() {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get<Array<CategoriesModel>>(environment.SERVICES_URL + 'categories/branch_offices/' + this.IdBranch, options);
    }

    // tslint:disable-next-line:variable-name
    updateBranchOfficeById(id_branch_office: string, branchOfficeInterface: any) {
        return this.http.put(`${ environment.SERVICES_URL }branch_offices/${ id_branch_office }`,
          branchOfficeInterface, {
              observe: 'response'
          });
    }

    // tslint:disable-next-line:variable-name
    deleteBranchOfficeById(id_branch_office: string) {
        return this.http.delete(`${ environment.SERVICES_URL }branch_offices/${ id_branch_office }`, {
            observe: 'response'
        });
    }

    update(id, dato: any, arraySchedule: any) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.put(environment.SERVICES_URL + 'branch_offices/' + id, {
            'name': dato.name.trim(),
            'address': dato.address.trim(),
            'phone': dato.phone,
            'schedule': arraySchedule,
            'postal_code': dato.pc,
            'city': dato.city.trim(),
            'state': dato.state.trim(),
            'email': dato.email,
            'commission': dato.comission,
            'allow_card_payment': dato.allow_card_payment,
            'latitude': dato.latitude,
            'length': dato.length,
            'image': dato.image.replace(/ /g, ''),
            "id_general_categories": dato.id_general_categories,
            "estatus": dato.estatus
        }, options);
    }

    // tslint:disable-next-line:variable-name
    getBranchOfficeById(id_branch_office: string) {
        return this.http.get<BranchOfficeInterface>(environment.SERVICES_URL + 'branch_offices/show/' + id_branch_office);
    }

    show_id(id) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get<Array<BranchOfficeModel>>(environment.SERVICES_URL + 'branch_offices/show/' + id, options);
    }

    show_all() {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders

        };
        return request = this.http.get<Array<BranchOfficeModel>>(environment.SERVICES_URL + 'branch_offices', options);
    }

    getBranchOfficesByName(nameBranchOffice: string, idCity: string): Observable<any> {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get<Array<BranchOfficeModel>>(environment.SERVICES_URL + `branch_offices/${nameBranchOffice}/city/${idCity}`, options);
    }

    // tslint:disable-next-line:variable-name
    getBranchOfficesByIdCity(id_city: string, page?: string, nameSearch?: string, statusSearch?: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${ environment.SERVICES_URL }branch_offices/web/show/all/${ id_city }`, {
            observe: 'response',
            params: {
                page,
                nameSearch,
                statusSearch
            }
        });
    }

    // tslint:disable-next-line:variable-name max-line-length
    getCoordinatesBranchOfficesByIdCity(id_city: string): Observable<HttpResponse<{ branch_offices_count: number, offices: [ { name: string, coordinates: { lat: number, lng: number } } ] }>> {
        return this.http.get<{ branch_offices_count: number, offices: [ { name: string, coordinates: { lat: number, lng: number } } ] }>(`${ environment.SERVICES_URL }branch_offices/web/show/all/${ id_city }/coordinates`, {
            observe: 'response',
        });
    }

    getActiveInactive(cityId) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get<Array<BranchOfficeModel>>(environment.SERVICES_URL + 'branch_offices/web/show/all/status/' + cityId, options);
    }


    updateStatus(id: string, status: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.put<Array<BranchOfficeModel>>(environment.SERVICES_URL + 'branch_offices/update/status/' + id, {
            "estatus": status
        }, options);
    }

    // tslint:disable-next-line:variable-name max-line-length
    getProductsByIdBranchOffice(page: string, name?: string, stock?: string, id_category?: string, products_additions?: string, is_scheduled?: string): Observable<any> {
        return this.http.get(
          `${ environment.SERVICES_URL }branch_offices/${ this.IdBranch }/products`, {
              observe: 'response',
              params: {
                  page,
                  name,
                  stock,
                  id_category,
                  products_additions,
                  is_scheduled
              }
          }
        );
    }

    // tslint:disable-next-line:variable-name max-line-length
    getProductsStats(id_branch_office: string): Observable<HttpResponse<{ count_product: number, count_price_original: number, allow_card_payment: boolean }>> {
        return this.http.get<any>(
          `${ environment.SERVICES_URL }branch_offices/${ id_branch_office }/products/stats`, {
              observe: 'response'
          }
        );
    }


    }
