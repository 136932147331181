<app-spinner></app-spinner>
<div class="page">
  <app-navbar></app-navbar>
  <div class="page-content d-flex align-items-stretch">

    <nav class="side-navbar">

      <app-sidebar></app-sidebar>
    </nav>

    <div class="content-inner">

      <div class="modal fade" id="selectionCity"  data-bs-backdrop="static" tabindex="-1" aria-labelledby="selectionCity" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Ciudades disponibles</h5>
            </div>

            <div class="modal-body">
              <h5>Selecciona ciudad de tu zona de distribución</h5>
              <p><i class="fas fa-info-circle mx-2 fs-5 text-primary"></i>Sólo podrás ver la información
                de la zona
                seleccionada.</p>

              <label for="validationTooltip04" class="form-label">Ciudad</label>
              <select id="validationTooltip04" class="form-select form-select-lg " required (change)="setValue($event)">
                <option value="{{'Selecciona la ciudad'}}" selected disabled>Selecciona Una ciudad</option>
                <option value="{{arrayCity._id}}" *ngFor="let arrayCity of arrayCities">{{arrayCity.city}}</option>
              </select>

            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" [disabled]="!currentCityId || currentCityId === 'Selecciona la ciudad'" (click)="save()">Continuar</button>
            </div>

          </div>
        </div>
      </div>
      <div class="collapse" id="collapseExample">
        <div class="card pr-1 Fcard-body pl-3 py-1 m-2 mb-md-3  mx-md-3">
          <div class="d-flex align-items-center bd-highlight ">
            <div class="w-100 bd-highlight">
              <i class="fas fa-info-circle"></i> Para activar permisos: Informacion del sitio > sonido >
              permitir.
            </div>
            <div class="flex-shrink-0 bd-highlight">
              <button type="button" class="btn btn-secondary fs-6 p-1 px-2">
                <p class="m-0" (click)="probarSonido()">Probar <i class="fas fa-play ml-2"></i></p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>
