import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CurrenciesPipe } from "./pipes/currencies.pipe";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxSpinnerModule } from "ngx-spinner";
import { UserDetailComponent } from "./components/user-detail/user-detail.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { BranchOfficeDetailComponent } from "./components/branch-office-detail/branch-office-detail.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { StatusOrderPipe } from "./pipes/status-order.pipe";
import { BranchOfficeReviewComponent } from "./components/branch-office-review/branch-office-review.component";
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    CurrenciesPipe,
    SpinnerComponent,
    UserDetailComponent,
    BranchOfficeDetailComponent,
    StatusOrderPipe,
    BranchOfficeReviewComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    RouterModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgCircleProgressModule.forRoot({
      radius: 30,
      outerStrokeWidth: 4,
      animationDuration: 1000 * 60,
      outerStrokeColor: '#ffffff',
      titleColor: '#ffffff',
      unitsColor: '#ffffff',
      clockwise: true,
      showInnerStroke: false,
      showSubtitle: false,
      startFromZero: false,
      renderOnClick: true,
      showUnits: true,
      units: 'Min',
    }),
    NgSelectModule,
    DragDropModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CurrenciesPipe,
    NgbTooltipModule,
    RouterModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgCircleProgressModule,
    SpinnerComponent,
    UserDetailComponent,
    BranchOfficeDetailComponent,
    StatusOrderPipe,
    NgSelectModule,
    BranchOfficeReviewComponent,
  ],
})
export class SharedModule {}
