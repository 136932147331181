<div class="page login-page">
  <div class="container d-flex align-items-center">
    <div class="form-holder has-shadow">
      <div class="row">
        <!-- Logo & Information Panel-->
        <div class="col-12 col-lg-6">
          <div class="info d-flex align-items-center justify-content-center">
            <div class="text-center">
              <img class="img-logo" src="assets/img/componente_icono.svg" />
              <div class="logo">
                <h1 class="title-1">COYOTE FOODS</h1>
              </div>
              <p class="subtitle-1">Transportamos sabor</p>
            </div>
          </div>
        </div>

        <!-- Form Panel -->
        <div class="col-12 col-lg-6 bg-white">
          <div class="form d-flex align-items-center">
            <div class="w-100">
              <form [formGroup]="loginForm" class="m-auto">
                <div class="form-group">
                  <label for="email" class="label-material input">Correo</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    class="input-material"
                    autocomplete="email"
                    [class.border-danger]="emailControl.touched && emailControl.invalid"
                    [formControl]="this.emailControl"
                  />
                  <small
                    class="text-danger"
                    *ngIf="emailControl.touched && emailControl.invalid"
                    >El correo no es válido</small
                  >
                </div>

                <div class="form-group">
                  <label for="password" class="label-material input"
                    >Contraseña</label
                  >
                  <input
                    type="password"
                    id="password"
                    name="password"
                    class="input-material"
                    autocomplete="current-password"
                    [class.border-danger]="passwordControl.touched && passwordControl.invalid"
                    [formControl]="this.passwordControl"
                  />
                  <small
                    class="text-danger"
                    *ngIf="passwordControl.touched && passwordControl.invalid"
                    >La contraseña no puede estar vacía</small
                  >
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-primary form-control Quick-Bold"
                    (click)="eventLogin()"
                    [disabled]="loginForm.disabled"
                  >
                    Iniciar sesión
                  </button>
                  <div class="d-block mt-3">
                    <a
                      class="forgot-pass"
                      [routerLink]="[AuthRouteNames.RECUPERACION]"
                      >¿Olvidaste tu contraseña?</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyrights text-center">
    <p>
      Designed by
      <img
        src="assets/img/logo-white-3.png"
        href="https://itelisoft.com/"
        style="width: 100px; margin-left: 5px"
        class="external"
        alt="Itelisoft Logo"
      />
    </p>
  </div>
</div>
