import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format24to12'
})
export class Format24to12Pipe implements PipeTransform {

  transform(time: any): any {

    let schedule = time.split(' ');
    let input_schedule = schedule[0];
    let output_schedule = schedule[1];

    let input_hour = (input_schedule.split(':'))[0];
    let input_min = (input_schedule.split(':'))[1];
    let input_part = input_hour >= 12 ? 'pm' : 'am';
    input_min = (input_min + '').length === 1 ? `0${input_min}` : input_min;
    input_hour = input_hour > 12 ? input_hour - 12 : input_hour;
    input_hour = (input_hour + '').length === 1 ? `0${input_hour}` : input_hour;


    let output_hour = (output_schedule.split(':'))[0];
    let output_min = (output_schedule.split(':'))[1];
    let output_part = output_hour >= 12 ? 'pm' : 'am';
    output_min = (output_min + '').length === 1 ? `0${output_min}` : output_min;
    output_hour = output_hour > 12 ? output_hour - 12 : output_hour;
    output_hour = (output_hour + '').length === 1 ? `0${output_hour}` : output_hour;


    return `${input_hour}:${input_min} ${input_part} - ${output_hour}:${output_min} ${output_part}`;
}

}
