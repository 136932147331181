import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


const bootstrap = require('bootstrap');

@Injectable({
  providedIn: 'root'
})
export class ModalCitieService {
  @Output() onComplete = new EventEmitter<void>();
  @Input() init: string = '';
  @Input() name: string = '';

  private currentCitieContent = new BehaviorSubject<string>('');
  private currentCitieNameContent = new BehaviorSubject<string>('');
  public currentCity = this.currentCitieContent.asObservable();
  public currentCityName = this.currentCitieNameContent.asObservable();


  modalEvent: Subject<any>;
  modalReference;

  constructor() {
    this.modalEvent = new Subject<any>();

  }

  updateCitie(init?: string, cityName?: string) {
    if (![null, undefined, ''].includes(init) && ![null, undefined, ''].includes(cityName)) {
      this.init = init;
      this.name = cityName;
      localStorage.setItem('currentCityId', this.init);
      localStorage.setItem('currentCity', this.name);
      this.currentCitieContent.next(this.init);
      this.currentCitieNameContent.next(this.name);
      this.onComplete.emit();
    }
  }

  show() {
    if (!this.modalReference)
      this.modalReference = new bootstrap.Modal(document.getElementById('selectionCity'), {
        backdrop: 'static',
        keyboard: false,
        focus:true
      });
    
    
    this.modalReference.show();
    this.modalEvent.next('show');
  }

  hide() {
    this.modalReference.hide();
    this.modalEvent.next('hide');
  }

}
