<div class="container-fluid Quick-Book">
  <div class="card">
    <div class="arrow-left cursor" ngbTooltip="Volver a la lista de sucursales">
      <i class="fa fa-arrow-left" [routerLink]="['../']" *ngIf="this.isSuperAdmin"></i>
    </div>
    <div class="card-header text-center">
      <h3 class="Quick-Bold"><i class="fa fa-comments"></i> Reseñas</h3>
    </div>
    <div class="card-body">
      <div class="card">
        <div class="card-body py-0">
          <div class="form-group"></div>

          <div class="row summary">
            <div class="col-12 col-sm d-flex flex-row flex-sm-column text-left text-sm-center justify-content-center">
              <p class="mr-1 mr-sm-0"><i class="fa fa-comments"></i> {{ groupBranchOffices ? 'Sucursales con reseñas' : 'Reseñas' }}</p>
              <p>{{ this.reviewsCounter || 0 }}</p>
            </div>
          </div>

          <div class="row">
            <div [class.col-sm-4]="this.isSuperAdmin" [class.col-sm-8]="!this.isSuperAdmin">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-comments"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Folio de reseña" (keyup)="this.eventReviewSearch($event)">
              </div>
            </div>
            <div [class.col-sm-4]="this.isSuperAdmin" *ngIf="this.isSuperAdmin">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-store"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Folio de sucursal" (keyup)="this.eventBranchOfficeSearch($event)">
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <select name="value"
                        id="value"
                        class="form-control"
                        [(ngModel)]="this.valueSearch"
                        (change)="this.fetchReviews(0)"
                >
                  <option *ngFor="let value of this.valueCodes | keyvalue" [value]="value.key">
                    {{ value.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-sm text-center align-self-center" *ngIf="isSuperAdmin">
              <div class="form-check">
                <input class="form-check-input"
                       type="checkbox"
                       id="group_branch_offices"
                       [ngModel]="this.groupBranchOffices"
                       (change)="this.groupBranchOffices = !this.groupBranchOffices; this.changePage(0)">
                <label class="form-check-label" for="group_branch_offices">
                  Agrupar por sucursales
                </label>
              </div>
            </div>
          </div>
          <div class="row m-1">
            <div class="col d-flex justify-content-center align-items-center" *ngFor="let i of [1, 2, 3, 4, 5]">
              <span>{{ i }}</span>
              <span class="mx-1">=</span>
              <img
                  class="d-block"
                  src="./../../../assets/evaluations/{{ i }}.svg"
                  height="16"
                  alt=""/>
            </div>
          </div>
        </div>
      </div>

      <div class="list-group mb-1" *ngIf="this.reviewsList.length === 0">
        <div class="list-group-item">
          <h5 class="Quick-Bold m-0 text-center">
            SIN RESEÑAS
          </h5>
        </div>
      </div>

      <div class="list-group mb-1" *ngIf="this.reviewsList && this.groupBranchOffices === true">
        <div class="list-group-item flex-column align-items-start"
             *ngFor="let review of reviewsList | paginate: { itemsPerPage: 12, currentPage: this.page, totalItems: this.reviewsCounter }">
          <div class="d-flex align-items-center">
            <img class="d-block mr-1"
                 [src]="review.image_branch_office"
                 [alt]="review.image_branch_office"/>
            <div>
              <div class="d-flex align-items-center">
                <img class="d-block mr-1"
                     src="./../../../assets/evaluations/{{ review.value_average?.toFixed(0) }}.svg"
                     height="20"
                     alt=""/>
                <h5 class="mb-1 Quick-Bold">
                  Calificación {{ review.value_average?.toFixed(2) }}
                </h5>
              </div>
              <blockquote class="blockquote p-2 m-0">
                <p class="h6 Quick-Bold mb-0">Reseñas {{ review.reviews_count }}</p>
                <footer class="blockquote-footer my-1">
                  <strong>{{ review.name_branch_office || 'SIN REGISTROS' }}</strong>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
            <div class="d-flex flex-column flex-sm-row justify-content-between flex-grow-1">
              <small class="cursor"
                     ngbTooltip="Sucursal copiada al portapapeles"
                     triggers="click"
                     (click)="this.Forms.copyToClipboard(review._id)">
                <strong>Sucursal</strong>&nbsp;
                <span>{{ review._id }}</span>
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="list-group mb-1" *ngIf="this.reviewsList && this.groupBranchOffices === false">
        <div class="list-group-item flex-column align-items-start"
             *ngFor="let review of reviewsList | paginate: { itemsPerPage: 12, currentPage: this.page, totalItems: this.reviewsCounter }">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex align-items-center">
              <img
                  class="d-block mr-1"
                  src="./../../../assets/evaluations/{{ review.value?.toFixed(0) }}.svg"
                  height="20"
                  alt=""
                  ngbTooltip="Review copiada al portapapeles"
                  triggers="click"
                  (click)="this.Forms.copyToClipboard(review._id)"/>
              <h5 class="mb-1 Quick-Bold">
                {{ review.title }}
              </h5>
            </div>
            <small class="d-none d-sm-block">{{ review.createdAt | date }}</small>
          </div>
          <blockquote class="blockquote p-2 m-0">
            <h6 *ngIf="review.text" class="Quick-Bold mb-0">"{{ review.text }}"</h6>
            <footer class="blockquote-footer my-1">
              <strong>{{ review.name_customer || 'SIN REGISTROS' }}</strong>
              <small class="d-block d-sm-none Quick-Book text-left">{{ review.createdAt | date }}</small>
            </footer>
          </blockquote>
          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
            <div class="d-flex flex-column flex-sm-row justify-content-between flex-grow-1">
              <small class="cursor"
                     ngbTooltip="Orden copiada al portapapeles"
                     triggers="click"
                     (click)="this.Forms.copyToClipboard(review.id_orders)">
                <strong>Orden</strong>&nbsp;
                <span>{{ review.id_orders }}</span>
              </small>
              <small class="cursor"
                     ngbTooltip="Cliente copiado al portapapeles"
                     triggers="click"
                     (click)="this.Forms.copyToClipboard(review.id_customers)">
                <strong>Cliente</strong> {{ review.id_customers }}
              </small>
              <small>
                <strong>Cliente teléfono</strong> {{ review.phone_customer || 'SIN REGISTROS' }}
              </small>
              <ng-template [ngIf]="this.isSuperAdmin">
                <small class="cursor"
                       ngbTooltip="Sucursal copiada al portapapeles"
                       triggers="click"
                       (click)="this.Forms.copyToClipboard(review.id_branch_offices)">
                  <strong>Folio sucursal</strong> {{ review.id_branch_offices || 'SIN REGISTROS' }}
                </small>
                <small><strong>Sucursal</strong> {{ review.name_branch_office || 'SIN REGISTROS' }}</small>
              </ng-template>
              <small *ngIf="this.isSuperAdmin === false"
                     class="cursor"
                     ngbTooltip="Review copiada al portapapeles"
                     triggers="click"
                     (click)="this.Forms.copyToClipboard(review._id)">
                <strong>Reseña</strong> {{ review._id }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <pagination-controls class="text-center mt-2" previousLabel="Atrás" nextLabel="Siguiente" (pageChange)="this.changePage($event)"></pagination-controls>
    </div>
  </div>
</div>
