export enum SuperAdminRouteNames {
  INICIO = "inicio",
  CIUDADES = "ciudades",
  SUCURSALES = "sucursales",
  PROMOCIONES = "promociones",
  USUARIOS = "usuarios",
  CLIENTES = "clientes",
  CUPONES = "cupones",
  CATEGORIAS_GENERALES = "categorias-generales",
  PEDIDOS = "pedidos",
  HISTORIAL_DE_PEDIDOS = "historial-de-pedidos",
  PEDIDOS_ENTREGADOS = "pedidos-entregados",
  PAGOS_CON_TARJETA = "pagos-con-tarjeta",
  REPORTE_DE_INGRESOS = "reporte-de-ingresos",
  REPARTIDORES = "repartidores",
  NOTIFICACIONES = "notificaciones",
  FINANZAS = "finanzas",
  NOTIFICACIONES_CLIENTES = "clientes",
  NOTIFICACIONES_REPARTIDORES = "repartidores",
  PREGUNTAS_Y_RESPUESTAS_FRECUENTES = "preguntas-y-respuestas-frecuentes",
  AGENTE_DE_COYOTE_FOODS = "agente-de-coyote-foods",
}
