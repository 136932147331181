import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToBase64Service {

  constructor() { }

  convert(file){
      return new Promise((res) => {
          let base64textString;

          var reader = new FileReader();

          reader.onload = _handleReaderLoaded.bind(this);

          reader.readAsBinaryString(file);


          function _handleReaderLoaded(readerEvt) {

              var binaryString = readerEvt.target.result;
              base64textString = btoa(binaryString);
              //console.log(btoa(binaryString));
              res(btoa(binaryString))

          }

      });
      
  }
  }

