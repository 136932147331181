<footer class="main-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <p>Coyote Foods &copy; {{ currentYear }}</p>
      </div>
      <div class="col-sm-6 text-right">
        <p>
          Designed by
          <img
            src="../../../../assets/img/logo-white-3.png"
            href="https://itelisoft.com/"
            style="width: 100px; margin-left: 5px"
            class="external"
          />
        </p>
      </div>
    </div>
  </div>
</footer>
