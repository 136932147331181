import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BranchOfficeInterface } from '../../models/branchOfficeModel';
import { UserInfoService } from '../../services/user-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SucursalesService } from '../../services/sucursales.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToBase64Service } from '../../Tools/to-base64.service';
import { UploadService } from '../../services/upload.service';
import { ModalCitieService } from '../../services/modal-citie.service';
import Swal from 'sweetalert2';
import { UserInterface } from '../../models/user.model';
import { Forms } from '../../Tools/forms';
import { ImagesService } from '../../services/images.service';
import { SuperAdminRouteNames } from "../../../modules/super-admin/super-admin-route.names";
import { CoreRouteNames } from "../../../core/core-route.names";
import { AdminSucursalRouteNames } from "../../../modules/admin-sucursal/admin-sucursal-route.names";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: [ './user-detail.component.scss' ]
})
export class UserDetailComponent implements OnInit, AfterViewInit {
  public readonly SuperAdminRouteNames = SuperAdminRouteNames;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('nameInput') nameInput: ElementRef;

  Forms = Forms;

  isSuperAdminLaPiedad = window.sessionStorage.getItem('id_city') === '6153df3ec14badc7f78362c7';
  flagFileTouched = false;
  imageFile: { file: File, localUrl: (string | ArrayBuffer), s3Url: string };
  imageToRemoveFromS3: { s3Url: string };

  branchOfficesList: BranchOfficeInterface[] = [];
  idUser: string;
  currentCity: string;

  flagLoading = true;

  form: FormGroup;

  isSuperAdmin: boolean;

  roleCodes = this.isSuperAdminLaPiedad ? { // backend -> frontend
    super_admin: 'Super administrador',
    admin_sucursal: 'Admin sucursal',
    support: 'Soporte'
  } : { // backend -> frontend
    admin_sucursal: 'Admin sucursal',
    support: 'Soporte'
  };

  constructor(
    private userService: UserInfoService,
    private route: ActivatedRoute,
    private router: Router,
    private sucursalesService: SucursalesService,
    private spinner: NgxSpinnerService,
    private toBase64: ToBase64Service,
    private uploadService: UploadService,
    private modalService: ModalCitieService,
    private imagesService: ImagesService
  ) {
  }

  ngOnInit() {
    /*const token: any = jwtDecode(sessionStorage.getItem('token'));*/
    const token: any = {};
    token.role = sessionStorage.getItem('role');
    this.isSuperAdmin = token && token.role && token.role === 'super_admin';

    if (this.isSuperAdmin) {
      this.modalService.currentCity.subscribe((citiesResponse) => {
        if (citiesResponse?.length > 0) {
          this.currentCity = citiesResponse;
          this.fetchBranchOffices();
        }
      });
    } else {
      this.currentCity = sessionStorage.getItem('currentCityId');
      this.fetchBranchOffices();
    }

    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.spinner.show();
    this.fetchUserData(this.isSuperAdmin ? this.route.snapshot.paramMap.get("usuario-id") : sessionStorage.getItem('id_user'));
  }

  private resetForm(): void {
    this.form.reset();
    this.flagFileTouched = false;
    this.imageFile = undefined;
  }

  private fetchUserData(idUser: string): void {
    if (idUser !== undefined && idUser !== null) {
      this.userService.getUserById(idUser).subscribe(userResponse => {
        if (Forms.handleData(userResponse, 200)) {
          this.idUser = idUser;
          this.role.disable();
          this.password.disable();
          const userInterface: UserInterface = userResponse.body[ 0 ];
          userInterface.password = '';
          this.imageFile = {
            file: null,
            localUrl: null,
            s3Url: userInterface.user_img
          };
          this.form.patchValue(userInterface);
          this.eventRoleChange();
        }
      }, error => Forms.handleError(error), () => this.spinner.hide());
    }
  }

  private fetchBranchOffices() {
    this.spinner.show();
    this.sucursalesService.getActiveInactive(this.currentCity).subscribe(
      (data) => {
        if (data?.length > 0) {
          this.branchOfficesList = data;
        }
        this.spinner.hide();
        this.flagLoading = false;
      });
  }

  private buildForm() {
    this.form = new FormGroup({
      name: new FormControl('', [ Validators.required ]),
      email: new FormControl('', [ Validators.required, Validators.email ]),
      password: new FormControl('', [ Validators.required ]),
      role: new FormControl('', [ Validators.required ]),
      id_branch_office: new FormControl('', [ Validators.required ]),
      phone: new FormControl('', [ Validators.required, Validators.minLength(10), Validators.maxLength(10) ])
    });

    if (!this.isSuperAdmin) {
      this.id_branch_office.disable();
    }
  }

  selectFile(event) {
    if (this.imageFile?.s3Url) {
      this.imageToRemoveFromS3 = { s3Url: this.imageFile.s3Url };
    }

    if (event.target.files.length > 0) {
      const fileList = event.target.files;
      this.imagesService.getImagesFromFiles(fileList).then(images => {
        this.imageFile = images[ 0 ];
      });
    }
  }

  removeImage() {
    this.flagFileTouched = true;
    if (this.imageFile.s3Url) {
      this.imageToRemoveFromS3 = { s3Url: this.imageFile.s3Url };
    }
    this.imageFile = undefined;
    this.fileInput.nativeElement.value = '';
  }

  private getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[ key ] === value);
  }

  eventRoleChange() {
    switch (this.role.value) {
      case this.getKeyByValue(this.roleCodes, this.roleCodes.super_admin): {
        this.id_branch_office.disable();
        this.phone.disable();
        break;
      }
      case this.getKeyByValue(this.roleCodes, this.roleCodes.admin_sucursal): {
        if (this.isSuperAdmin) {
          this.id_branch_office.enable();
        }
        this.phone.disable();
        break;
      }
      case this.getKeyByValue(this.roleCodes, this.roleCodes.support): {
        this.id_branch_office.disable();
        this.phone.enable();
        break;
      }
    }

    if (this.role.value === this.roleCodes.super_admin) {
      this.id_branch_office.setValue(sessionStorage.getItem('id_branch_office'));
      this.id_branch_office.clearValidators();
    } else if (this.role.value === this.roleCodes.admin_sucursal) {
      this.id_branch_office.setValidators([ Validators.required ]);
    }
    this.id_branch_office.updateValueAndValidity();
  }

  saveUser() {
    if (this.form.valid && this.imageFile) {
      this.spinner.show();

      const userInterface: UserInterface = this.form.value;
      userInterface.id_city = this.currentCity;

      if (this.idUser) {
        userInterface._id = this.idUser;
        this.updateUser(userInterface, false);
      } else {
        this.createUser(userInterface);
      }
    } else {
      this.form.markAllAsTouched();
      this.flagFileTouched = true;
    }
  }

  updateImages(userInterface: UserInterface): void {
    this.uploadService.uploadUserFiles(
      (this.idUser) ? this.idUser : userInterface._id,
      new Array<{ file: File; localUrl: string | ArrayBuffer }>(this.imageFile)
    ).subscribe((s3Response) => {

      if (this.imageToRemoveFromS3) {
        this.uploadService.deleteFiles(new Array<{ s3Url: string }>(this.imageToRemoveFromS3)).subscribe((deleteResponse) => {
          if (Forms.handleData(deleteResponse, 200)) {
            // console.log(deleteResponse);
          }
          this.imageToRemoveFromS3 = undefined;
        });
      }

      if (s3Response.status === 201 && s3Response.body) {
        const body: any = s3Response.body;
        this.imageFile = {
          file: null,
          localUrl: null,
          s3Url: body.s3Urls[ 0 ].url
        };
        userInterface.user_img = this.imageFile.s3Url;
      }

      this.updateUser(userInterface, true);
    }, error => {
      if (error.status === 0) {
        if (this.idUser === undefined) {
          this.userService.Delete(userInterface._id).subscribe(() => { // delete new user created
          });
        }

        Swal.fire({
          icon: 'error',
          title: 'Problema al subir las imágenes',
          text: 'HTTP Code 413'
        });
        this.spinner.hide();
      } else {
        Forms.handleError(error);
      }
    });
  }

  private createUser(userInterface: UserInterface): void {
    if (
      this.role.value === this.getKeyByValue(this.roleCodes, this.roleCodes.super_admin)
      || this.role.value === this.getKeyByValue(this.roleCodes, this.roleCodes.support)
    ) {
      userInterface.id_branch_office = '5f1b7956ad5c37368023bd05'; // sucursal general

      if (this.role.value === this.getKeyByValue(this.roleCodes, this.roleCodes.support)) {
        userInterface.active = true;
      }
    }
    this.userService.createUser(userInterface).subscribe(userResponse => {
      if (Forms.handleData(userResponse, 201)) {
        userInterface = userResponse.body[ 0 ];
        this.updateImages(userInterface);
      } else {
        if (userResponse.body?.error?.length > 0) {
          this.email.setErrors({
            email_duplicate: 'Correo registrado en otro usuario'
          });
        } else {
          Forms.handleError(userResponse);
        }
        this.spinner.hide();
      }
    }, error => Forms.handleError(error));
  }

  updateUser(userInterface: UserInterface, updateImages: boolean): void {
    this.userService.updateUser(userInterface).subscribe(userUpdateResponse => {
      if (Forms.handleData(userUpdateResponse, 201)) {
        if (updateImages) {
          if (this.idUser) { // updated
            Swal.fire({
              icon: 'success',
              title: 'Usuario actualizado'
            }).then(() => {
              if (this.isSuperAdmin) {
                this.router.navigate([ CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.USUARIOS ]).then();
              } else {
                this.router.navigate([ CoreRouteNames.ADMIN_SUCURSAL, AdminSucursalRouteNames.INICIO ]).then();
              }
            });
          } else { // created
            Swal.fire({
              icon: 'success',
              title: 'Usuario creado',
              showConfirmButton: true,
              confirmButtonColor: '#e1780f',
              confirmButtonText: '<span class="Quick-Bold">Crear otro</span>',
              showCancelButton: true,
              cancelButtonText: '<span class="Quick-Book">Ver usuarios</span>',
              allowOutsideClick: false,
              onAfterClose: () => {
                this.nameInput.nativeElement.focus();
              }
            }).then(value => {
              if (value.isConfirmed) {
                this.resetForm();
              } else {
                this.router.navigate([ CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.USUARIOS ]).then();
              }
            });
          }
          this.spinner.hide();
        } else {
          this.updateImages(userInterface);
        }
      } else {
        if (userUpdateResponse.body?.error?.length > 0) {
          this.email.setErrors({
            email_duplicate: 'Correo registrado en otro usuario'
          });
        } else {
          Forms.handleError(userUpdateResponse);
        }
        this.spinner.hide();
      }
    }, error => {
      Forms.handleError(error);
      this.spinner.hide();
    });
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get role(): AbstractControl {
    return this.form.get('role');
  }

  get id_branch_office(): AbstractControl {
    return this.form.get('id_branch_office');
  }

  get phone(): AbstractControl {
    return this.form.get('phone');
  }
}
