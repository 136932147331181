import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormControl } from "@angular/forms";
import { UserService } from "src/app/shared/services/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

import { AuthRouteNames } from "../auth-route.names";
import { Router } from "@angular/router";
import { CoreRouteNames } from "../../../core-route.names";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public readonly AuthRouteNames = AuthRouteNames;
  loginForm = this.formBuilder.group({
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(
          "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
        ),
      ],
    ],
    password: ["", [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {}

  ngOnInit() {}

  private saveUserData(data: any): void {
    sessionStorage.setItem("mail", data.user.email);
    sessionStorage.setItem("token", data.token);
    sessionStorage.setItem("name", data.user.name);
    sessionStorage.setItem("role", data.user.role);
    sessionStorage.setItem("img", data.user.user_img);
    sessionStorage.setItem("id_user", data.user._id);
    sessionStorage.setItem('id_branch_office', data.user.id_branch_office);

    this.userService.logged = true;
    this.userService.isSuperAdmin = data.user.role === 'super_admin';
    if (this.userService.isSuperAdmin) {
      sessionStorage.setItem('id_city', data.user.id_city);
    }

    /*
    * es un super-admin
    * y no es super-admin de la piedad
    * */
    if (
      this.userService.isSuperAdmin &&
      data.user.id_city !== '6153df3ec14badc7f78362c7'
    ) {
      localStorage.setItem('currentCityId', data.user.id_city);
    } else {
      sessionStorage.setItem('currentCityId', data.user.id_city);
    }

    this.router.navigate(["/"]);
  }

  private openLoadingAlert(): void {
    Swal.fire({
      title: "Iniciando sesión...",
      timer: 1000,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }

  eventLogin(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      this.loginForm.enable();
    }

    this.loginForm.disable();
    this.userService
      .Login(this.emailControl.value, this.passwordControl.value)
      .subscribe(
        (data: any) => {
          if (data.error && data.error === "invalid_credentials") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "¡Correo o contraseña incorrectos!",
            });
            this.loginForm.enable();
          } else {
            this.openLoadingAlert();
            this.saveUserData(data);
          }
        },
        (responseError) => {
          console.error(responseError);
          this.spinner.hide();
          Swal.fire({
            icon: "error",
            title: "HTTP Code " + responseError.status,
            text: responseError.message,
          }).then(() => {
            if (responseError.status === 0) {
              window.location.reload();
            }
          });
        },
      );
  }

  get emailControl() {
    return this.loginForm.get("email") as FormControl;
  }

  get passwordControl() {
    return this.loginForm.get("password") as FormControl;
  }
}
