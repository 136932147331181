import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthRouteNames } from "../../core/modules/auth/auth-route.names";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    user;
    hasSession = false;
    inLogin = true;
    logged = false;
    token: string;
    isSuperAdmin = false;
    url = environment.SERVICES_URL;

    constructor(public http: HttpClient,
                private router: Router) {
        this.token = sessionStorage.getItem('token');
        this.isSuperAdmin = sessionStorage.getItem('role') === 'super_admin';


    }


    update(id, dato: any) {

        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.put(this.url + 'users/' + id, {
            'name': dato.name.trim(),
            'email': dato.email.trim(),
            'role': dato.role,
            'user_img': dato.user_img,
            'id_branch_office': dato.idBranchOffice,
        }, options);
        alert(request);
    }

    sendUserPasswordMessage(email) {
        return this.http.get(this.url + 'users/message/' + email.trim());
    }

    updatePassword(id, token, password) {
        let request;
        return request = this.http.put(this.url + 'users/password/' + id, {
            password: password.trim(),
            tokenpassword: token

        });
    }

    public isLoggedIn() {
        const _mail = sessionStorage.getItem('mail');
        if (!!_mail) {
            this.user = _mail;
            this.hasSession = true;
            this.inLogin = false;
        }
        return this.hasSession;
    }

    public Login(email: string, password: string) {
        return this.http.post(`${ this.url }users/login`, {
            email,
            password
        });
    }

    public logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.logged = false;
        this.user = null;
        this.inLogin = true;
        this.hasSession = false;
        window.location.reload();
    }

    deleteAdminAssociatedWithBranch(id): Observable<any> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return this.http.delete(this.url + 'users/admin/' + id, options);
    }
}
