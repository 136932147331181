import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../../shared/services/user.service";

@Injectable({
  providedIn: "root",
})
export class PublicGuard implements CanActivate {
  constructor(
    public userService: UserService,
    public router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return !this.userService.isLoggedIn();
  }
}
