import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/shared/services/user.service";
import { Router } from "@angular/router";
import { SocketService } from "src/app/shared/services/socket.service";
let bootstrap = require("bootstrap");
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  route: string;
  collpaseNavBar = false;
  audio = new Audio("/assets/sounds/ringtones-heart.mp3");

  constructor(
    private userService: UserService,
    private router: Router,
    private socket: SocketService,
  ) {}

  ngOnInit(): void {
    import('src/assets/js/front.js');
  }

  logOut() {
    this.userService.logout();
    this.socket.close();
  }

  probarSonido() {
    const button = document.getElementById("play");
    button.addEventListener("click", (e) => this.audio.play());
    this.audio.play();
    this.audio.loop = true;
    Swal.fire({
      icon: "info",
      title: "Reproduciendo",
      html: '<i class="fas fa-info-circle"></i> <strong>Si no estás escuchando el sonido por favor ve a: Configuración del sitio > sonido > permitir.</strong>',
      // text: 'Se está reproduciendo el sonido de prueba.',
      timerProgressBar: true,
      timer: 4000,
    }).then(() => {
      this.audio.pause();
    });
  }

  collapseT() {
    var collapseElementList = [].slice.call(
      document.querySelectorAll(".collapse"),
    );
    var collapseList = collapseElementList.map(function (collapseEl) {
      return new bootstrap.Collapse(collapseEl);
    });
    // console.log(collapseList)
  }
}
