<div class="container-fluid">
  <div class="card">
    <div class="arrow-left cursor"
         [ngbTooltip]="(this.userService.isSuperAdmin) ? 'Volver a la lista de sucursales' : 'Volver'">
      <i class="fa fa-arrow-left" (click)="this.locationService.back()"></i>
    </div>
    <div class="card-header text-center">
      <h4 class="Quick-Bold">{{ (this.idBranchOffice) ? 'Editar sucursal' : 'Registrar sucursal' }}</h4>
    </div>

    <form [formGroup]="this.branchOfficeForm" class="card-body p-2">
      <div class="card">
        <div class="card-header Quick-Bold">
          <h5>Datos generales</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">

              <!-- current imagen selected -->
              <div class="row">
                <div class="col">
                  <label class="form-control-label Quick-Bold" ngbTooltip="Se mostrará en la aplicación">
                    Imagen de Sucursal *
                  </label>
                  <div class="text-center mb-2" *ngIf="this.imageFile">
                    <img alt="..."
                         class="main-image"
                         [src]="(this.imageFile.s3Url) ? this.imageFile.s3Url : this.imageFile.localUrl"
                         [ngbTooltip]="this.userService.isSuperAdmin ? '' : this.superAdminMessage"
                         placement="bottom">
                  </div>
                </div>
              </div>

              <!-- fileInput -->
              <div class="row" *ngIf="this.userService.isSuperAdmin">
                <div class="col">
                  <div class="custom-file">
                    <input type="file"
                           name="file"
                           id="file"
                           class="Quick-Book custom-file-input"
                           accept="image/png, image/jpeg"
                           #fileInput
                           (change)="this.onSelectImageEvent($event)">
                    <label for="file"
                           class="custom-file-label"
                           [class.border-danger]="this.image.hasError('error')">
                      Subir imagen
                    </label>
                  </div>
                </div>
              </div>

              <!-- delete image button -->
              <div class="row text-center my-2" *ngIf="this.userService.isSuperAdmin && this.imageFile">
                <div class="col">
                  <button type="button"
                          class="btn btn-danger Quick-Bold"
                          (click)="this.onRemoveImageEvent()">
                    <i class="fas fa-trash"></i> Borrar imagen
                  </button>
                </div>
              </div>

              <div class="row mt-2">
                <form class="col-sm-12" [formGroup]="this.phonesForm">
                  <div class="card">
                    <div class="card-header Quick-Bold">
                      Teléfonos *
                    </div>
                    <div class="card-body p-2">
                      <div class="form-group">
                        <label for="phone" class="form-control-label Quick-Bold">Teléfono</label>
                        <div class="row">
                          <div class="col d-flex justify-content-between align-items-center">
                            <input type="text"
                                   name="phone"
                                   id="phone"
                                   maxlength="10"
                                   class="form-control Quick-Book d-block"
                                   placeholder="Teléfono a 10 dígitos sin espacios"
                                   formControlName="phone"
                                   (keyup.enter)="this.onSavePhoneEvent()">
                            <div class="btn-cat px-2">
                              <i class="fas fa-plus-circle"
                                 ngbTooltip="Guardar teléfono"
                                 (click)="this.onSavePhoneEvent()"></i>
                            </div>
                          </div>
                          <span class="text-danger text-center small"
                                *ngIf="this.phone.hasError('error')">
                            {{ this.phone.getError('error') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div cdkDropList class="drag-list" (cdkDropListDropped)="this.onDragPhoneEvent($event)"
                         *ngIf="this.phones.value.length > 0">
                      <div cdkDrag class="drag-box Quick-Book" *ngFor="let phone of this.phones.value; let i = index">
                        <span class="Quick-Book">
                          {{ phone }} -&nbsp;
                          <span class="Quick-Bold"
                                ngbTooltip="Recibe notificaciones de pedidos nuevos y es el número que llaman los clientes desde la aplicación"
                                *ngIf="i == 0">Primario</span>
                          <span class="Quick-Bold"
                                ngbTooltip="Recibe notificaciones de pedidos nuevos"
                                *ngIf="i != 0">Secundario</span>
                        </span>
                        <i class="far fa-trash-alt mx-2"
                           ngbTooltip="Eliminar teléfono"
                           (click)="this.onDeletePhoneEvent(i)"></i>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="form-control-label Quick-Bold">Nombre *</label>
                    <input type="text"
                           name="name"
                           id="name"
                           placeholder="Nombre de la sucursal"
                           class="form-control Quick-Book"
                           formControlName="name"
                           #nameInput>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="form-control-label Quick-Bold border-danger">Correo *</label>
                    <input type="email"
                           name="email"
                           id="email"
                           placeholder="Correo electrónico asociado"
                           class="form-control Quick-Book"
                           formControlName="email">
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group" [ngbTooltip]="(!this.userService.isSuperAdmin) ? this.superAdminMessage : ''">
                    <label class="form-control-label Quick-Bold">Comisión % *</label>
                    <input type="number"
                           name="commission"
                           id="commission"
                           min="0"
                           placeholder="Porcentaje de comisión"
                           class="form-control Quick-Book"
                           formControlName="commission">
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group" [ngbTooltip]="(!this.userService.isSuperAdmin) ? this.superAdminMessage : ''">
                    <label class="form-control-label Quick-Bold">Tiempo promedio por pedido</label>
                    <input type="number"
                           name="average_order_time"
                           id="average_order_time"
                           min="0"
                           placeholder="Tiempo promedio en minutos"
                           class="form-control Quick-Book"
                           formControlName="average_order_time">
                  </div>
                </div>

                <div class="col-sm-6 align-self-center">
                  <div class="form-group form-check">
                    <input type="checkbox"
                           name="allow_card_payment"
                           id="allow_card_payment"
                           class="form-check-input"
                           formControlName="allow_card_payment">
                    <label
                        class="form-control-label form-check-label Quick-Bold"
                        for="allow_card_payment"
                        [ngbTooltip]="(this.userService.isSuperAdmin)
                          ? (this.flagAllowCardPayments)
                          ? ''
                          : 'Uno o varios productos tienen precios incorrectos, verificalos para habilitar esta opción'
                          : this.superAdminMessage">
                      Permitir pagos con tarjeta
                    </label>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="form-control-label Quick-Bold"
                           ngbTooltip="CLABE (Clave bancaria estandarizada) de 18 dígitos para recibir pagos con tarjeta">CLABE</label>
                    <input type="text"
                           name="clabe"
                           id="clabe"
                           placeholder="CLABE a 18 dígitos sin espacios"
                           class="form-control Quick-Book"
                           maxlength="18"
                           formControlName="clabe">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header Quick-Bold">
          <h5>
            Ubicación
            <span
                class="badge badge-pill badge-danger"
                ngbTooltip="Si la ubicación en mapa es correcta, guarda cambios"
                *ngIf="this.userService.isSuperAdmin && this.idBranchOffice && this.location.value.coordinates?.length === 0">verificar dirección en mapa</span>
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group" [ngbTooltip]="(!this.userService.isSuperAdmin) ? this.superAdminMessage : ''">
                <label class="form-control-label Quick-Bold">Código Postal</label>
                <input type="text"
                       name="postal_code"
                       id="postal_code"
                       formControlName="postal_code"
                       minlength="5"
                       maxlength="5"
                       placeholder="Código postal de la sucursal"
                       class="form-control Quick-Book"
                       readonly>
              </div>
            </div>

            <div class="col-sm-9">
              <div class="form-group" [ngbTooltip]="(!this.userService.isSuperAdmin) ? this.superAdminMessage : ''">
                <label class="form-control-label Quick-Bold">Dirección *</label>
                <input type="text"
                       name="address"
                       id="address"
                       placeholder="Dirección de la sucursal"
                       class="form-control Quick-Book"
                       formControlName="address">
                <span class="text-danger small"
                      *ngIf="this.address.hasError('error')">Escoge una ubicación en el mapa.</span>
              </div>
            </div>

            <div class="col-sm-12">
              <div #googleMaps id="googleMaps"></div>
            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header Quick-Bold">
          <h5>Categorías y Horarios</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group" [ngbTooltip]="(!this.userService.isSuperAdmin) ? this.superAdminMessage : ''">
                <label class="form-control-label Quick-Bold">Categorías generales *</label>
                <ng-select
                    [items]="this.generalCategoriesList"
                    bindLabel="name"
                    bindValue="_id"
                    formControlName="id_general_categories"
                    [multiple]="true"
                    [closeOnSelect]="false">
                </ng-select>
              </div>

              <form class="row flex-column" [formGroup]="this.categoryForm">
                <div class="col">
                  <div class="card">
                    <div class="card-header Quick-Bold">
                      Categorías
                    </div>
                    <div class="card-body p-2">
                      <div class="form-group">
                        <label for="category_name" class="form-control-label Quick-Bold">Nombre</label>
                        <div class="row">
                          <div class="col d-flex justify-content-between align-items-center">
                            <input type="text"
                                   name="category_name"
                                   id="category_name"
                                   class="form-control Quick-Book d-block"
                                   placeholder="Escribe el nombre de la categoría"
                                   formControlName="name"
                                   (keyup.enter)="this.onSaveCategoryEvent()"
                                   #categoryInput>
                            <div class="btn-cat px-2 cursor">
                              <i [ngClass]="{
                                    'fas fa-plus-circle': this.category_model.value === null,
                                    'fa fa-pencil': this.category_model.value !== null
                                    }"
                                 (click)="this.onSaveCategoryEvent()"
                                 ngbTooltip="Guardar categoría"></i>
                            </div>
                          </div>
                          <span class="text-danger text-center small"
                                *ngIf="this.category_name.hasError('error')">
                            {{ this.category_name.getError('error') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div cdkDropList class="drag-list" (cdkDropListDropped)="this.onDragCategoryEvent($event)"
                         *ngIf="this.branchOfficeCategoriesList.length > 0">
                      <div cdkDrag class="drag-box Quick-Book" *ngFor="let category of this.branchOfficeCategoriesList"
                           [class.Quick-Bold]="this.category_model.value === category">
                        <span>{{ category.name }}</span>
                        <span class="d-flex align-items-center">
                            <i class="fa fa-pencil mx-2"
                               ngbTooltip="Editar categoría"
                               (click)="this.onEditCategoryEvent(category)"></i>
                            <i class="far fa-trash-alt mx-2"
                               ngbTooltip="Eliminar categoría"
                               (click)="this.onDeleteCategoryEvent(category)"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <form class="col-sm-6" [formGroup]="this.scheduleForm">
              <div class="card">
                <div class="card-header Quick-Bold">
                  Horarios
                </div>
                <div class="card-body p-2">
                  <div class="form-group">
                    <label class="form-control-label Quick-Bold">Días</label>
                    <ng-select
                        [items]="this.dayList"
                        bindLabel="day_name"
                        bindValue="week_day"
                        formControlName="week_days"
                        [multiple]="true"
                        [closeOnSelect]="false">
                    </ng-select>
                  </div>

                  <div class="form-group">
                    <label for="is_multiple_schedule" class="form-control-label Quick-Bold">Horario adicional</label>
                    <div class="form-check">
                      <input type="checkbox"
                             name="is_multiple_schedule"
                             id="is_multiple_schedule"
                             class="form-check-input"
                             formControlName="is_multiple_schedule">
                      <label class="form-check-label form-control-label" for="is_multiple_schedule">
                        Al activarse, se agregará como un horario adicional.
                      </label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label Quick-Bold">Horario de entrada</label>
                        <input type="time"
                               name="start_time"
                               id="start_time"
                               class="form-control Quick-Book"
                               formControlName="start_time">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label Quick-Bold">Horario de salida</label>
                        <div class="row">
                          <div class="col d-flex justify-content-between align-items-center">
                            <input type="time"
                                   name="close_time"
                                   id="close_time"
                                   class="form-control Quick-Book d-block"
                                   formControlName="close_time">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 btn-cat px-2 text-center">
                      <i class="fas fa-plus-circle"
                         ngbTooltip="Agregar horario"
                         (click)="this.onSaveScheduleEvent()"></i>
                    </div>
                  </div>
                </div>

                <div cdkDropList class="drag-list" (cdkDropListDropped)="this.onDragScheduleEvent($event)"
                     *ngIf="this.schedule.value.length > 0">
                  <div cdkDrag class="drag-box Quick-Book"
                       *ngFor="let schedule of this.schedule.value; let scheduleIndex = index">
                    <div>
                        <span class="Quick-Bold">
                          {{ this.dayList[schedule.day - 1].day_name }}
                        </span>
                      <br>
                      <ul>
                        <li *ngFor="let hours of schedule.open_hours; let openHourIndex = index">
                          <span class="Quick-Book">{{ hours.start_time }} - {{ hours.close_time }}</span>
                          <i class="far fa-trash-alt mx-2" ngbTooltip="Eliminar horario"
                             (click)="this.onDeleteScheduleEvent(scheduleIndex, openHourIndex)"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>


          </div>
        </div>

        <div class="form-group text-center">
          <button class="btn btn-primary boton-size Quick-Bold"
                  (click)="this.onSubmitEvent()"
                  [ngStyle]="{'opacity': (this.branchOfficeForm.invalid || this.imageFile === undefined || this.phones.value.length < 1) ? 0.5 : 1 }"
                  [ngbTooltip]="(this.branchOfficeForm.invalid || this.imageFile === undefined || this.phones.value.length < 1) ? 'Completa los campos que tengan un *' : ''">
            {{ (this.idBranchOffice) ? 'Guardar cambios' : 'Crear sucursal' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
