export class CategoriesModel implements CategoriesInterface {
  _id?: string;
  name?: string;
  id_branch_offices;
  categories;
}

export interface CategoriesInterface {
  _id?: string;
  name?: string;
  id_branch_offices;
  categories;
}

export class categoriesListModel {
  category_url?: string;
  _id: string;
  name: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  image?: { file: File, localUrl: string }; // only on webapp
}
