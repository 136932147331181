import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoriesInterface, CategoriesModel } from '../../models/categories.model';
import { SucursalesService } from '../../services/sucursales.service';
import { CategoriesService } from '../../services/categories.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from '../../services/upload.service';
import { ModalCitieService } from '../../services/modal-citie.service';
import Swal from 'sweetalert2';
import { BranchOfficeInterface } from '../../models/branchOfficeModel';
import { CitiesService } from '../../services/cities.service';
import { CitiesInterface } from '../../models/cities.model';
import { Subscription } from 'rxjs';
import { ImagesService } from '../../services/images.service';
import { GoogleMapsService } from '../../services/google-maps.service';
import { Forms } from '../../Tools/forms';
import { debounceTime } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UserService } from "../../services/user.service";
import { CoreRouteNames } from "../../../core/core-route.names";
import { AdminSucursalRouteNames } from "../../../modules/admin-sucursal/admin-sucursal-route.names";
import { SuperAdminRouteNames } from "../../../modules/super-admin/super-admin-route.names";

@Component({
  selector: 'app-branch-office-detail',
  templateUrl: './branch-office-detail.component.html',
  styleUrls: [ './branch-office-detail.component.scss' ]
})
export class BranchOfficeDetailComponent implements OnInit, OnDestroy {
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('categoryInput') categoryInput: ElementRef;

  dayList = [
    { week_day: 1, day_name: 'Lunes' },
    { week_day: 2, day_name: 'Martes' },
    { week_day: 3, day_name: 'Miércoles' },
    { week_day: 4, day_name: 'Jueves' },
    { week_day: 5, day_name: 'Viernes' },
    { week_day: 6, day_name: 'Sábado' },
    { week_day: 7, day_name: 'Domingo' },
  ];

  branchOfficeForm: FormGroup;
  phonesForm = this.formBuilder.group({
    phone: [ '', [ Validators.required, Validators.minLength(10), Validators.maxLength(10) ] ]
  });
  categoryForm = this.formBuilder.group({
    'category_model': [ null ],
    'name': [ '', [ Validators.required, Validators.minLength(1) ] ],
  });
  scheduleForm = this.formBuilder.group({
    'week_days': [ [], Validators.required ],
    'is_multiple_schedule': [ false, Validators.required ],
    'start_time': [ '', Validators.required ],
    'close_time': [ '', Validators.required ],
  });
  idBranchOffice: string;
  currentCityId: string;
  currentCity: CitiesInterface;
  flagAllowCardPayments = true;
  subscriptionClabe: Subscription;
  subscriptionPhone: Subscription;
  subscriptionAddress: Subscription;


  private mapComponents: {
    // @ts-ignore
    map: google.maps.Map
    // @ts-ignore
    marker: google.maps.Marker,
    // @ts-ignore
    infoWindow: google.maps.InfoWindow
  };

  imageFile: { file: File, localUrl: (string | ArrayBuffer), s3Url: string };
  imageToRemoveFromS3: { s3Url: string };

  generalCategoriesList: CategoriesModel[] = [];
  branchOfficeCategoriesList: CategoriesModel[] = [];


  superAdminMessage = 'Solo la administración de Coyote puede cambiar esta información';

  constructor(
    private spinner: NgxSpinnerService,
    private sucursalesService: SucursalesService,
    private categoriesService: CategoriesService,
    private citiesService: CitiesService,
    public locationService: Location,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private uploadService: UploadService,
    private modalService: ModalCitieService,
    private imagesService: ImagesService,
    private googleMapsService: GoogleMapsService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();

    this.createBranchOfficeFormAndListeners();

    this.idBranchOffice = this.userService.isSuperAdmin
      ? this.route.snapshot.params["sucursal-id"]
      : sessionStorage.getItem("id_branch_office");

    if (this.userService.isSuperAdmin) {
      this.modalService.currentCity.subscribe((citiesResponse) => {
        if (citiesResponse) {
          this.currentCityId = citiesResponse;
          this.fetchGeneralData(this.idBranchOffice);
        }
      });
    } else {
      this.currentCityId = sessionStorage.getItem('currentCityId');
      this.fetchGeneralData(this.idBranchOffice);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionClabe.unsubscribe();
    this.subscriptionPhone.unsubscribe();
    this.subscriptionAddress.unsubscribe();
  }

  private async initMap(): Promise<void> {
    const center = { lat: Number(this.currentCity.centerLat), lng: Number(this.currentCity.centerLong) };

    // @ts-ignore
    const map = await this.googleMapsService.initMap('googleMaps', { center, zoom: 14 });

    // @ts-ignore
    const marker = new google.maps.Marker({ map, position: center });

    // @ts-ignore
    const infoWindow = new google.maps.InfoWindow({ content: 'Selecciona la ubicación de la sucursal' });
    infoWindow.open({ map, anchor: marker, shouldFocus: true });

    this.mapComponents = { map, marker, infoWindow };

    if (this.userService.isSuperAdmin) {
      // @ts-ignore
      google.maps.event.addListener(map, 'click', (event) => {
        event.stop();
        this.updateMapPosition({ center: event.latLng });
      });
    }

    if (this.idBranchOffice) { // if branch office exists
      if (this.location.value.coordinates?.length) {
        this.updateMapPosition({
          center: {
            lat: this.location.value.coordinates[1],
            lng: this.location.value.coordinates[0]
          }
        });
      }
    }
  }

  private updateMapPosition(params: { center?: { lat: number, lng: number }, address?: string }): void {
    if (params.center) {
      this.mapComponents.marker.setPosition(params.center);

      if (
        params.center.lat === this.location.value.coordinates[1]
        && params.center.lng === this.location.value.coordinates[0]
      ) {
        this.mapComponents.infoWindow.setContent(this.address.value);
      } else {
        this.googleMapsService.getDataFromMarker(this.mapComponents.marker.getPosition().toJSON()).subscribe(mapsResponse => {
          // console.log(mapsResponse.body);
          if (mapsResponse.body.status !== 'ZERO_RESULTS') {
            const bestResult = mapsResponse.body.results[0];
            this.mapComponents.infoWindow.setContent(bestResult?.formatted_address);
            const postalCode = bestResult?.address_components?.filter(addressComponents => addressComponents.types[0] === 'postal_code')
              [0]?.short_name;

            this.branchOfficeForm.patchValue({
              postal_code: (postalCode) ? postalCode : '',
              address: (bestResult.formatted_address) ? bestResult.formatted_address : '',
              location: { coordinates: [ bestResult?.geometry.location.lng, bestResult?.geometry.location.lat ] }
            }, { emitEvent: false });
          }
        }, error => Forms.handleError(error, this.spinner));
      }
    }

    if (params.address) {
      this.googleMapsService.getDataFromAddress(params.address).subscribe(mapsResponse => {

        if (mapsResponse.body.status !== 'ZERO_RESULTS') {
          const bestResult = mapsResponse.body.results[0];
          this.mapComponents.marker.setPosition(bestResult?.geometry.location);
          this.mapComponents.infoWindow.setContent(bestResult?.formatted_address);

          const postalCode = bestResult?.address_components?.filter(addressComponents => addressComponents.types[0] === 'postal_code')
            [0]?.short_name;
          this.branchOfficeForm.patchValue({
            postal_code: (postalCode) ? postalCode : '',
            location: { coordinates: [ bestResult?.geometry.location.lng, bestResult?.geometry.location.lat ] }
          }, { emitEvent: false });
        }

      }, error => Forms.handleError(error, this.spinner));
    }
  }

  fetchGeneralData(idBranchOffice: string): void {
    this.citiesService.get(this.currentCityId).subscribe((citiesResponse: CitiesInterface[]) => {
      this.currentCity = citiesResponse[0];
      this.categoriesService.showAllGeneralCategory().subscribe((generalCategoriesResponse: Array<CategoriesInterface>) => {
        this.generalCategoriesList = generalCategoriesResponse;

        if (idBranchOffice === undefined) {
          if (!this.userService.isSuperAdmin) {
            this.router.navigateByUrl('/').then();
          }
          this.initMap();
          this.spinner.hide();
        } else {
          this.fetchBranchOffice(idBranchOffice);
        }
      }, error => Forms.handleError(error, this.spinner));
    }, error => Forms.handleError(error, this.spinner));
  }

  private fetchBranchOffice(idBranchOffice: string): void {
    this.sucursalesService.getBranchOfficeById(idBranchOffice).subscribe((branchOfficeResponse) => {
      if (!branchOfficeResponse) { // product not found
        Swal.fire({
          icon: 'error',
          title: 'Sucursal no encontrada'
        }).then();
        this.idBranchOffice = undefined;
        if (this.userService.isSuperAdmin) {
          this.spinner.hide();
        } else {
          this.router.navigateByUrl('/').then();
        }
      } else {
        this.sucursalesService.getProductsStats(idBranchOffice).subscribe(productsStatsResponse => {
          if (Forms.handleData(productsStatsResponse, 200)) {
            this.flagAllowCardPayments = productsStatsResponse.body.allow_card_payment;
            if (!this.flagAllowCardPayments) {
              this.allow_card_payment.disable();
            }
          }

          this.idBranchOffice = idBranchOffice;
          this.imageFile = {
            file: null,
            localUrl: null,
            s3Url: branchOfficeResponse.image
          };

          this.branchOfficeForm.patchValue(branchOfficeResponse, { emitEvent: false }); // general patch
          this.branchOfficeForm.patchValue({ // specific patch
            phone: '',
            phones: (branchOfficeResponse.phones.length > 0) ? branchOfficeResponse.phones : [branchOfficeResponse.phone]
          }, { emitEvent: false });
          this.initMap();

          this.categoriesService.GetByID(this.idBranchOffice).subscribe((categoriesResponse: Array<any>) => {
            if (categoriesResponse && categoriesResponse[0] && categoriesResponse[0].categories) {
              this.branchOfficeCategoriesList = categoriesResponse[0].categories;
            } else {
              console.log(categoriesResponse);
            }
            this.spinner.hide();
          });
        });
      }
    }, error => Forms.handleError(error, this.spinner));
  }

  private resetAllForms(): void {
    this.branchOfficeForm.reset({
      estatus: 'activo',
      location: { coordinates: [] },
      allow_card_payment: false,
      phone: '',
      phones: [],
      id_general_categories: [],
      schedule: []
    });

    this.phonesForm.reset({ 'phone': '' });
    this.categoryForm.reset({ 'name': '' });

    this.imageFile = undefined;
    this.imageToRemoveFromS3 = undefined;
    this.branchOfficeCategoriesList = [];

    if (this.userService.isSuperAdmin) {
      this.fileInput.nativeElement.value = '';
    }
  }

  createBranchOfficeFormAndListeners() {
    this.branchOfficeForm = this.formBuilder.group({
      // hidden
      estatus: [ 'activo' ],
      location: [ { coordinates: [] } ],
      city: [ null ],
      state: [ null ],
      id_city: [ null ],

      // general
      image: [ null ],
      name: [ null, [ Validators.required, Validators.minLength(1), Validators.maxLength(40) ] ],
      email: [ null, [ Validators.required, Validators.email ] ],
      commission: [ null, [ Validators.required, Validators.pattern('^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$'), Validators.min(0) ] ],
      average_order_time: [ null, [ Validators.min(0) ] ],
      allow_card_payment: [ false ],
      clabe: [ null, [ Validators.pattern('^[0-9]*$'), Validators.minLength(18), Validators.maxLength(18) ] ],
      phone: [ [] ],
      phones: [ [] ],

      // location
      postal_code: [ null, [ Validators.pattern('^[0-9]*$'), Validators.minLength(5), Validators.maxLength(5) ] ],
      address: [ null, [ Validators.required ] ],

      // categories
      id_general_categories: [ [], [ Validators.required ] ],

      // schedules
      schedule: [ [] ],
    });

    this.subscriptionClabe = this.clabe.valueChanges.subscribe(value => {
      this.clabe.setValue(value?.replace(/[^0-9]/g, ''), { emitEvent: false });
    });

    this.subscriptionPhone = this.phone.valueChanges.subscribe(value => {
      this.phone.setValue(value?.replace(/[^0-9]/g, ''), { emitEvent: false });
    });

    this.subscriptionAddress = this.address.valueChanges.pipe(debounceTime(1500)).subscribe(() => {
      if (this.address.valid) {
        this.updateMapPosition({ address: this.address.value });
      }
    });

    if (!this.userService.isSuperAdmin) {
      this.commission.disable();
      this.average_order_time.disable();
      this.allow_card_payment.disable();
      this.postal_code.disable();
      this.address.disable();
      this.location.disable();
      this.id_general_categories.disable();
    }
  }

  onSelectImageEvent(event) {
    if (this.imageFile?.s3Url) {
      this.imageToRemoveFromS3 = { s3Url: this.imageFile.s3Url };
    }

    if (event.target.files.length === 0) this.image.setErrors({ error: true });

    if (event.target.files.length > 0) {
      const fileList = event.target.files;
      this.imagesService.getImagesFromFiles(fileList).then(images => {
        this.imageFile = images[0];
        this.image.setErrors(null);
      });
    }
  }

  onRemoveImageEvent() {
    if (this.imageFile.s3Url) {
      this.imageToRemoveFromS3 = { s3Url: this.imageFile.s3Url };
    }
    this.imageFile = undefined;
    this.fileInput.nativeElement.value = '';
    this.image.setErrors({ error: true });
  }

  onSubmitEvent(): void {
    if (this.branchOfficeForm.invalid || this.imageFile === undefined || this.currentCity === undefined || this.phones.value.length < 1) {
      if (this.imageFile === undefined) this.image.setErrors({ error: true });

      if (this.phones.value.length < 1) {
        this.phonesForm.markAllAsTouched();
        this.phone.setErrors({ error: 'Se necesita un teléfono como mínimo.' });
      }

      if (this.userService.isSuperAdmin && this.location.value.coordinates?.length === 0) {
        this.address.setErrors({ error: true });
      }

      this.branchOfficeForm.markAllAsTouched();
      return;
    }

    this.spinner.show();

    this.branchOfficeForm.patchValue({
      phone: this.phones.value[0],
      city: this.currentCity.city,
      state: this.currentCity.state,
      id_city: this.currentCity._id
    }, { emitEvent: false });

    const branchOfficeInterface: BranchOfficeInterface = this.branchOfficeForm.value;

    if (this.idBranchOffice) { // update current branch office
      this.updateImages(branchOfficeInterface);
    } else { // create a new branch office
      this.createBranchOffice(branchOfficeInterface);
    }
  }

  createBranchOffice(branchOfficeInterface: BranchOfficeInterface): void {
    this.sucursalesService.createBranchOffice(branchOfficeInterface).subscribe(branchOfficeResponse => {
      if (branchOfficeResponse.status === 200 && branchOfficeResponse.body) {
        const body: any = branchOfficeResponse.body;
        if (body && body.error) {
          this.spinner.hide();
          Swal.fire({ // email duplicate error
            icon: 'error',
            title: 'Error',
            text: body.error.toUpperCase()
          }).then();
        }
      } else if (Forms.handleData(branchOfficeResponse, 201)) {
        branchOfficeInterface = branchOfficeResponse.body[0];
        this.updateImages(branchOfficeInterface);
      }
    }, error => Forms.handleError(error, this.spinner));
  }

  updateImages(branchOfficeInterface: BranchOfficeInterface): void {
    this.uploadService.uploadMarketFiles(
      (this.idBranchOffice) ? this.idBranchOffice : branchOfficeInterface._id,
      new Array<{ file: File; localUrl: string | ArrayBuffer }>(this.imageFile)
    ).subscribe((s3Response) => {

      if (this.imageToRemoveFromS3) {
        this.uploadService.deleteFiles(new Array<{ s3Url: string }>(this.imageToRemoveFromS3)).subscribe((deleteResponse) => {
          if (Forms.handleData(deleteResponse, 200)) {
            // console.log(deleteResponse);
          }
          this.imageToRemoveFromS3 = undefined;
        });
      }

      if (s3Response.status === 201 && s3Response.body) {
        const body: any = s3Response.body;
        this.imageFile = {
          file: null,
          localUrl: null,
          s3Url: body.s3Urls[0].url
        };
      }

      branchOfficeInterface.image = this.imageFile.s3Url;

      if (this.idBranchOffice === undefined) {
        this.categoriesService.Create(this.branchOfficeCategoriesList, branchOfficeInterface._id).subscribe(() => {
          // console.log(categoriesResponse);
        }, error => Forms.handleError(error, this.spinner));
      } else {
        branchOfficeInterface._id = this.idBranchOffice;

        this.categoriesService.updateCategoriesByIdBranchOffice(
          this.idBranchOffice,
          this.branchOfficeCategoriesList
        ).subscribe(() => {
          // console.log(categoriesResponse);
        }, error => Forms.handleError(error, this.spinner));
      }
      this.updateBranchOffice(branchOfficeInterface);
    }, error => {
      if (error.status === 0) {
        if (this.idBranchOffice === undefined) { // delete new branch office created to avoid garbage data
          this.sucursalesService.deleteBranchOfficeById(branchOfficeInterface._id).subscribe(() => {
          });
        }
        Swal.fire({
          icon: 'error',
          title: 'Problema al subir las imágenes',
          text: 'HTTP Code 413'
        }).then();
        this.spinner.hide();
      } else {
        Forms.handleError(error, this.spinner);
      }
    });
  }

  updateBranchOffice(branchOfficeInterface: BranchOfficeInterface): void {
    this.sucursalesService.updateBranchOfficeById(
      branchOfficeInterface._id,
      branchOfficeInterface
    ).subscribe(branchOfficeUpdateResponse => {
      if (branchOfficeUpdateResponse.status === 200 && branchOfficeUpdateResponse.body) {
        const body: any = branchOfficeUpdateResponse.body;
        if (body && body.error) {
          this.spinner.hide();
          Swal.fire({ // email duplicate error
            icon: 'error',
            title: 'Error',
            text: body.error.toUpperCase()
          }).then();
        }
      } else if (Forms.handleData(branchOfficeUpdateResponse, 201)) {
        if (this.idBranchOffice) { // updated
          Swal.fire({
            icon: 'success',
            title: 'Sucursal actualizada'
          }).then(() => {
            if (this.userService.isSuperAdmin) {
              this.locationService.back();
            } else {
              this.router.navigateByUrl('/').then();
            }
          });
        } else { // created
          Swal.fire({
            icon: 'success',
            title: 'Sucursal creada',
            showConfirmButton: true,
            confirmButtonColor: '#e1780f',
            confirmButtonText: '<span class="Quick-Bold">Crear otra</span>',
            showCancelButton: true,
            cancelButtonText: '<span class="Quick-Book">Ver sucursales</span>',
            allowOutsideClick: false,
            onAfterClose: () => {
              this.nameInput.nativeElement.focus();
            }
          }).then(value => {
            if (value.isConfirmed) {
              this.resetAllForms();
            } else {
              this.router.navigate([ CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.SUCURSALES ]).then();
            }
          });
        }
        this.spinner.hide();
      }
    }, error => Forms.handleError(error, this.spinner));
  }

  onSavePhoneEvent() {
    if (this.phone.value.length < 10) { // phone is short
      this.phone.setErrors({ error: 'El teléfono es muy corto, se necesitan 10 números sin espacios.' });
      return;
    }

    if (this.phones.value.includes(this.phone.value)) { // phone is duplicate
      this.phone.setErrors({ error: 'El teléfono ya ha sido agregado.' });
      return;
    }

    if (this.phones.value.length === 3) { // phones limit reached
      this.phone.setErrors({ error: 'Sólo se pueden agregar 3 números por sucursal.' });
      return;
    }

    if (this.phonesForm.invalid) {
      this.phonesForm.markAllAsTouched();
      return;
    }

    this.phones.value.push(this.phone.value);
    this.phonesForm.reset({ phone: '' });
  }

  onDeletePhoneEvent(phoneIndex: number) {
    this.phones.value.splice(phoneIndex, 1);
  }

  onDragPhoneEvent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.phones.value, event.previousIndex, event.currentIndex);
  }

  onDragCategoryEvent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.branchOfficeCategoriesList, event.previousIndex, event.currentIndex);
  }

  onSaveCategoryEvent() {
    if (this.categoryForm.invalid) {
      this.category_name.setErrors({ error: 'El nombre de la categoría no puede estar vacío.' })
      this.categoryForm.markAllAsTouched();
      return;
    }

    if (this.category_model.value !== null) { // update category
      this.category_model.value.name = this.category_name.value;
    } else { // create category
      const categoryModel = new CategoriesModel();
      categoryModel.name = this.category_name.value;
      this.branchOfficeCategoriesList.push(categoryModel);
    }

    this.categoryForm.reset({ name: '' });
  }

  onEditCategoryEvent(category: CategoriesModel) {
    this.category_model.setValue(category);
    this.category_name.setValue(category.name);
    this.categoryInput.nativeElement.focus();
  }

  onDeleteCategoryEvent(category: CategoriesModel) {
    this.branchOfficeCategoriesList.splice(this.branchOfficeCategoriesList.indexOf(category), 1);
  }

  onSaveScheduleEvent() {
    if (this.scheduleForm.invalid) {
      this.scheduleForm.markAllAsTouched();
      return;
    }

    this.week_days.value.forEach(weekDay => {
      const scheduleData = this.schedule.value.find(scheduleElement => scheduleElement.day === weekDay);
      if (scheduleData) { // update schedule
        if (this.is_multiple_schedule.value) { // append hours
          scheduleData.open_hours.push({ start_time: this.start_time.value, close_time: this.close_time.value });
        } else { // restart hours
          scheduleData.open_hours = ([ { start_time: this.start_time.value, close_time: this.close_time.value } ]);
        }
      } else { // create schedule
        this.schedule.value.push({
          day: weekDay,
          open_hours: [ { start_time: this.start_time.value, close_time: this.close_time.value } ]
        });
      }
    });
  }

  onDeleteScheduleEvent(scheduleIndex: number, openHourIndex: number) {
    if (this.schedule.value[scheduleIndex].open_hours.length === 1) { // remove day
      this.schedule.value.splice(scheduleIndex, 1);
    } else { // remove hours
      this.schedule.value[scheduleIndex].open_hours.splice(openHourIndex, 1);
    }
  }

  onDragScheduleEvent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.schedule.value, event.previousIndex, event.currentIndex);
  }


  get name(): AbstractControl {
    return this.branchOfficeForm.get('name');
  }

  get email(): AbstractControl {
    return this.branchOfficeForm.get('email');
  }

  get address(): AbstractControl {
    return this.branchOfficeForm.get('address');
  }

  get phones(): AbstractControl {
    return this.branchOfficeForm.get('phones');
  }

  get postal_code(): AbstractControl {
    return this.branchOfficeForm.get('postal_code');
  }

  get city(): AbstractControl {
    return this.branchOfficeForm.get('city');
  }

  get state(): AbstractControl {
    return this.branchOfficeForm.get('state');
  }

  get commission(): AbstractControl {
    return this.branchOfficeForm.get('commission');
  }

  get average_order_time(): AbstractControl {
    return this.branchOfficeForm.get('average_order_time');
  }

  get allow_card_payment(): AbstractControl {
    return this.branchOfficeForm.get('allow_card_payment');
  }

  get clabe(): AbstractControl {
    return this.branchOfficeForm.get('clabe');
  }

  get location(): AbstractControl {
    return this.branchOfficeForm.get('location');
  }

  get image(): AbstractControl {
    return this.branchOfficeForm.get('image');
  }

  get id_general_categories(): AbstractControl {
    return this.branchOfficeForm.get('id_general_categories');
  }

  get estatus(): AbstractControl {
    return this.branchOfficeForm.get('estatus');
  }

  get id_city(): AbstractControl {
    return this.branchOfficeForm.get('id_city');
  }

  get schedule(): AbstractControl {
    return this.branchOfficeForm.get('schedule');
  }

  /*
   * Phones Form
   * */
  get phone(): AbstractControl {
    return this.phonesForm.get('phone');
  }

  /*
  * Category Form
  * */
  get category_model(): AbstractControl {
    return this.categoryForm.get('category_model');
  }

  get category_name(): AbstractControl {
    return this.categoryForm.get('name');
  }

  /*
   * Schedule Form
   * */
  get week_days(): AbstractControl {
    return this.scheduleForm.get('week_days');
  }

  get is_multiple_schedule(): AbstractControl {
    return this.scheduleForm.get('is_multiple_schedule');
  }

  get start_time(): AbstractControl {
    return this.scheduleForm.get('start_time');
  }

  get close_time(): AbstractControl {
    return this.scheduleForm.get('close_time');
  }

}
