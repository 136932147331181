<!-- Sidebar Header-->
<div
  class="sidebar-header d-flex flex-column justify-content-center align-items-center"
>
  <img
    src="assets/img/imagen_coyote.png"
    alt="..."
    class="img-thumbnail"
  />
  <div class="title-1">
    <h1 class="h4">
      <p>Coyote Foods</p>
    </h1>
  </div>
</div>

<div
  class="text-center"
  role="button"
  (click)="this.isSuperAdminLaPiedad && openSelectCityModal()"
  *ngIf="(modalService.currentCity | async) && userRole === 'super_admin'"
>
  <p>
    {{ modalService.currentCityName | async }}
    <i class="far fa-edit mx-1" *ngIf="this.isSuperAdminLaPiedad"></i>
  </p>
</div>

<!-- Sidebar Navigation Menus-->
<ng-container *ngIf="userRole === 'super_admin'">
  <ul>
    <li
      [routerLink]="[CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.INICIO]"
      routerLinkActive="active"
    >
      <a class="user-select-none"><i class="fas fa-home"></i>Inicio</a>
    </li>

    <li *ngIf="this.isSuperAdminLaPiedad"
      [routerLink]="[CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.CIUDADES]"
      routerLinkActive="active"
    >
      <a class="user-select-none"
        ><i class="fas fa-map-marker-alt"></i>Ciudades</a
      >
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.SUCURSALES
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none d-lg-flex align-items-lg-center">
        <i class="fas fa-store"></i>
        Sucursales
        <span class="badge badge-pill badge-primary ml-lg-1">nuevo</span>
      </a>
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.PROMOCIONES
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none d-lg-flex align-items-lg-center">
        <i class="fas fa-tags"></i>
        Promociones
        <span class="badge badge-pill badge-primary ml-lg-1">nuevo</span>
      </a>
    </li>

    <li
      [routerLink]="[CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.USUARIOS]"
      routerLinkActive="active"
    >
      <a class="user-select-none"><i class="fas fa-user"></i>Usuarios</a>
    </li>

    <li
      [routerLink]="[CoreRouteNames.SUPER_ADMIN, SuperAdminRouteNames.CLIENTES]"
      routerLinkActive="active"
    >
      <a class="user-select-none"><i class="fas fa-users"></i>Clientes</a>
    </li>

    <li (click)="isCouponsSelected = !isCouponsSelected">
      <a
        class="user-select-none d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-between"
      >
        <div><i class="fa fa-tags"></i>Cupones</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isCouponsSelected,
            'fa-caret-down': !isCouponsSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isCouponsSelected">
      <li [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.CUPONES,
        CouponRouteNames.LISTADO
      ]"
      routerLinkActive="active">
        <a class="sub-list"><i class="fas fa-percent"></i>Cupones</a>
      </li>

      <li [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.CUPONES,
        CouponRouteNames.CUPONES_CANJEADOS
      ]"
      routerLinkActive="active">
        <a class="sub-list"><i class="fas fa-check-double"></i>Cupones canjeados</a>
      </li>
    </ng-container>
    <!-- End Coupons -->

    <li
      *ngIf="this.isSuperAdminLaPiedad"
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.CATEGORIAS_GENERALES
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none"
        ><i class="fa fa-list-alt"></i>Categorías generales</a
      >
    </li>

    <!-- Orders -->
    <li (click)="isOrdersSelected = !isOrdersSelected">
      <a
        class="user-select-none d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-between"
      >
        <div><i class="fas fa-list-ol"></i>Administrar pedidos</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isOrdersSelected,
            'fa-caret-down': !isOrdersSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isOrdersSelected">
      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.PEDIDOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"><i class="fas fa-utensils"></i>Del día</a>
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.PEDIDOS_ENTREGADOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
          ><i class="fas fa-check-circle"></i>Entregados y cancelados</a
        >
      </li>
    </ng-container>
    <!-- End Orders -->

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.HISTORIAL_DE_PEDIDOS
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none"
        ><i class="fas fa-scroll"></i>Historial de pedidos</a
      >
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.PAGOS_CON_TARJETA
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none"
        ><i class="fas fa-credit-card"></i>Pagos con tarjeta</a
      >
    </li>

    <!-- Finance -->
    <li (click)="isFinanceSelected = !isFinanceSelected">
      <a
        class="user-select-none d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-between"
      >
        <div><i class="fa fa-line-chart"></i>Finanzas</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isFinanceSelected,
            'fa-caret-down': !isFinanceSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isFinanceSelected">
      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.FINANZAS,
          FinanceRouteNames.CARTERA_COYOTE
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
        ><i class="fa fa-shopping-bag"></i>Cartera Coyote</a
        >
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.FINANZAS,
          FinanceRouteNames.REPORTE_DE_INGRESOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"><i class="fas fa-money-bill"></i>Reporte de ingresos</a>
      </li>
    </ng-container>
    <!-- End Finance -->

    <!-- Orders -->
    <li (click)="isDealersSelected = !isDealersSelected">
      <a
        class="user-select-none d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-between"
      >
        <div><i class="fas fa-motorcycle"></i>Repartidores</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isDealersSelected,
            'fa-caret-down': !isDealersSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isDealersSelected">
      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.REPARTIDORES,
          DealerRouteNames.LISTADO
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"><i class="fa fa-list-ul"></i>Listado</a>
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.REPARTIDORES,
          DealerRouteNames.CALIFICACIONES
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
        ><i class="fa fa-comments"></i>Reseñas</a
        >
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.REPARTIDORES,
          DealerRouteNames.INCENTIVOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
        ><i class="fas fa-check-circle"></i>Incentivos</a
        >
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.SUPER_ADMIN,
          SuperAdminRouteNames.REPARTIDORES,
          DealerRouteNames.CARTERAS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
        ><i class="fas fa-shopping-bag"></i>Carteras</a
        >
      </li>
    </ng-container>
    <!-- End Orders -->

    <!--<li routerLink="/pages/evaluations" routerLinkActive="active">
      <a class="user-select-none"><i class="fas fa-clipboard-list"></i>Evaluaciones generales</a>
    </li>-->

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.NOTIFICACIONES,
        SuperAdminRouteNames.NOTIFICACIONES_CLIENTES
      ]"
      routerLinkActive="active"
    >
      <a class="subtitles"
        ><i class="fas fa-envelope-open-text"></i>Notificaciones a clientes</a
      >
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.NOTIFICACIONES,
        SuperAdminRouteNames.NOTIFICACIONES_REPARTIDORES
      ]"
      routerLinkActive="active"
    >
      <a class="subtitles"
        ><i class="fas fa-envelope-open-text"></i>Notificaciones a
        repartidores</a
      >
    </li>

    <li
      *ngIf="this.isSuperAdminLaPiedad"
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.PREGUNTAS_Y_RESPUESTAS_FRECUENTES
      ]"
      routerLinkActive="active"
    >
      <a class="subtitles"
        ><i class="fas fa-question-circle"></i>Preguntas frecuentes</a
      >
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.SUPER_ADMIN,
        SuperAdminRouteNames.AGENTE_DE_COYOTE_FOODS
      ]"
      routerLinkActive="active"
    >
      <a class="subtitles"
        ><i class="fa fa-gitlab"></i>Agente de Coyote Foods</a
      >
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="userRole === 'admin_sucursal'">
  <ul>
    <li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.INICIO
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none"><i class="fas fa-home"></i>Inicio</a>
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.PRODUCTOS
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none d-lg-flex align-items-lg-center">
        <i class="fas fa-box-open"></i>
        Productos
        <span class="badge badge-pill badge-primary ml-lg-1">nuevo</span>
      </a>
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.PAGOS_CON_TARJETA
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none"
        ><i class="fas fa-credit-card"></i>Pagos con tarjeta</a
      >
    </li>

    <!-- Orders -->
    <li (click)="isOrdersSelected = !isOrdersSelected">
      <a class="user-select-none d-flex justify-content-between">
        <div><i class="fas fa-list-ol"></i>Pedidos</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isOrdersSelected,
            'fa-caret-down': !isOrdersSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isOrdersSelected">
      <li
        [routerLink]="[
          CoreRouteNames.ADMIN_SUCURSAL,
          AdminSucursalRouteNames.PEDIDOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"><i class="fas fa-cart-plus"></i>Nuevos</a>
      </li>

      <li
        [routerLink]="[
          CoreRouteNames.ADMIN_SUCURSAL,
          AdminSucursalRouteNames.PEDIDOS_ENTREGADOS
        ]"
        routerLinkActive="active"
      >
        <a class="sub-list"
          ><i class="fas fa-check-circle"></i>Entregados y cancelados</a
        >
      </li>

      <!--<li routerLink="/pages/pedidos-cancelados" routerLinkActive="active">
        <a class="sub-list"> <i class="fas fa-dumpster"></i> Cancelados</a>
      </li>-->
    </ng-container>
    <!-- End Orders -->

    <!--<li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.REPARTIDORES
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none">
        <i class="fas fa-people-carry"></i>Repartidores</a
      >
    </li>-->

    <!-- Coupons -->
    <li (click)="isCouponsSelected = !isCouponsSelected">
      <a class="user-select-none d-flex justify-content-between">
        <div><i class="fa fa-tags"></i>Cupones</div>
        <i
          class="fas"
          [ngClass]="{
            'fa-caret-up': isCouponsSelected,
            'fa-caret-down': !isCouponsSelected
          }"
        ></i>
      </a>
    </li>

    <ng-container *ngIf="isCouponsSelected">
      <li [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.CUPONES,
        CouponRouteNames.LISTADO
      ]"
      routerLinkActive="active">
        <a class="sub-list"><i class="fas fa-percent"></i>Cupones</a>
      </li>
      <li [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.CUPONES,
        CouponRouteNames.CUPONES_CANJEADOS
      ]"
      routerLinkActive="active">
        <a class="sub-list"
          ><i class="fas fa-check-double"></i>Cupones canjeados</a
        >
      </li>
    </ng-container>
    <!-- End Coupons -->

    <li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.HISTORIAL_DE_PEDIDOS
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none d-lg-flex align-items-lg-center">
        <i class="fas fa-scroll"></i>
        Historial de pedidos
      </a>
    </li>

    <li
      [routerLink]="[
        CoreRouteNames.ADMIN_SUCURSAL,
        AdminSucursalRouteNames.CALIFICACIONES
      ]"
      routerLinkActive="active"
    >
      <a class="user-select-none d-lg-flex align-items-lg-center">
        <i class="fa fa-comments"></i>
        Reseñas
        <span class="badge badge-pill badge-primary ml-lg-1">nuevo</span>
      </a>
    </li>
  </ul> </ng-container
><!-- Modal -->
