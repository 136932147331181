import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CitiesModel } from '../models/cities.model';
import { Observable } from 'rxjs';
import { cpuUsage } from 'process';
import * as http from 'http';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  token: string;
  url = environment.SERVICES_URL;

  constructor(public http: HttpClient) {
    this.token = sessionStorage.getItem('token');
  }

  getCities() {
    let request;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

    const options = {
      headers: httpHeaders
    };
    return request = this.http.get(this.url + 'cities', options);
  }

  get(_id):Observable<any> {
    let request;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

    const options = {
      headers: httpHeaders
    };
    return request = this.http.get(this.url + 'cities/' + _id, options);
  }

  createCity(city:CitiesModel) {
    let request;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

    const options = {
      headers: httpHeaders
    };
    console.log(this.url + 'cities', city, options)
    return request = this.http.post(this.url + 'cities',city, options);
  }

  updateCity(city: CitiesModel, idCity) {
    let request;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

    const options = {
      headers: httpHeaders
    };
    console.log(this.url + 'cities/' + idCity, city, options)
    return request = this.http.put(this.url + 'cities/' + idCity, city, options);
  }

  // tslint:disable-next-line:variable-name
  getWalletsByCity(id_city: string, status: string, dealer?: string, start_date_iso?: string, end_date_iso?: string): Observable<any> {
    return this.http.get(
      this.url + `cities/${ encodeURIComponent(id_city) }/wallets`, {
        observe: 'response',
        params: {
          status,
          dealer,
          from: start_date_iso,
          to: end_date_iso,
        }
      }
    );
  }

}
