import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  LOCALE_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
} from "@angular/core";
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { Format24to12Pipe } from "./shared/pipes/format24to12.pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { NgApexchartsModule } from "ng-apexcharts";

import { RoundProgressModule } from "angular-svg-round-progressbar";
import { NgxSpinnerModule } from "ngx-spinner";
import es from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";

registerLocaleData(es);

@NgModule({
  declarations: [AppComponent, Format24to12Pipe],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // PagesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgxPaginationModule,
    NgApexchartsModule,
    NgxSpinnerModule,
    RoundProgressModule,
    NgbTooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: "es-MX",
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "$",
    },
  ],
  exports: [Format24to12Pipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
