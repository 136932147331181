export enum AdminSucursalRouteNames {
  INICIO = "inicio",
  MI_PERFIL = "mi-perfil",
  MI_SUCURSAL = "mi-sucursal",
  PEDIDOS = "pedidos",
  PEDIDOS_ENTREGADOS = "pedidos-entregados",
  HISTORIAL_DE_PEDIDOS = "historial-de-pedidos",
  // REPARTIDORES = "repartidores",
  PAGOS_CON_TARJETA = "pagos-con-tarjeta",
  PRODUCTOS = "productos",
  CALIFICACIONES = "calificaciones",
  CUPONES = "cupones",
}
