import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencies'
})
export class CurrenciesPipe implements PipeTransform {

  transform(value: number | string): string {
    return new CurrencyPipe('en-US', '').transform(
      value,
      '',
      '$',
      '1.2-2',
      'en-US'
    );
  }

}
