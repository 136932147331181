<header class="header">
  <nav class="navbar">
    <!-- Search Box-->
    <div class="container-fluid">
      <div class="navbar-holder d-flex align-items-center justify-content-between">
        <!-- Navbar Header-->
        <div class="navbar-header">
          <!-- Navbar Brand --><a class="navbar-brand d-none d-sm-inline-block">
          <div class="brand-text d-none d-lg-inline-block"><span>Panel de </span><strong> Control</strong>
          </div>
          <div class="brand-text d-none d-sm-inline-block d-lg-none"><strong>PC</strong></div>
        </a>
          <!-- Toggle Button--><span id="toggle-btn"
                                     class="menu-btn active"><span></span><span></span><span></span></span>
        </div>
        <!-- Navbar Menu -->



        <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
          <!-- Search-->
          <!-- <li class="nav-item d-flex align-items-center"><a id="search"><i class="icon-search"></i></a></li>-->
          <!-- Notifications-->
          <!--<li class="nav-item dropdown"> <a id="notifications" rel="nofollow" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link"><i class="fa fa-bell-o"></i><span class="badge bg-red badge-corner">12</span></a>
            <ul aria-labelledby="notifications" class="dropdown-menu">
              <li><a rel="nofollow" href="#" class="dropdown-item">
                  <div class="notification">
                    <div class="notification-content"><i class="fa fa-envelope bg-green"></i>You have 6 new messages </div>
                    <div class="notification-time"><small>4 minutes ago</small></div>
                  </div></a></li>
              <li><a rel="nofollow" href="#" class="dropdown-item">
                  <div class="notification">
                    <div class="notification-content"><i class="fa fa-twitter bg-blue"></i>You have 2 followers</div>
                    <div class="notification-time"><small>4 minutes ago</small></div>
                  </div></a></li>
              <li><a rel="nofollow" href="#" class="dropdown-item">
                  <div class="notification">
                    <div class="notification-content"><i class="fa fa-upload bg-orange"></i>Server Rebooted</div>
                    <div class="notification-time"><small>4 minutes ago</small></div>
                  </div></a></li>
              <li><a rel="nofollow" href="#" class="dropdown-item">
                  <div class="notification">
                    <div class="notification-content"><i class="fa fa-twitter bg-blue"></i>You have 2 followers</div>
                    <div class="notification-time"><small>10 minutes ago</small></div>
                  </div></a></li>
              <li><a rel="nofollow" href="#" class="dropdown-item all-notifications text-center"> <strong>view all notifications                                            </strong></a></li>
            </ul>
          </li>-->
          <!-- Messages                        -->
          <!-- <li class="nav-item dropdown"><a id="messages" rel="nofollow" data-target="#" href="#"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            class="nav-link"><i class="fa fa-envelope-o"></i><span
                   class="badge bg-orange badge-corner">10</span></a>
               <ul aria-labelledby="notifications" class="dropdown-menu">
                   <li><a rel="nofollow" href="#" class="dropdown-item d-flex">
                       <div class="msg-profile"><img src="../../../assets/img/avatar-1.jpg" alt="..."
                                                     class="img-fluid rounded-circle"></div>
                       <div class="msg-body">
                           <h3 class="h5">Jason Doe</h3><span>Sent You Message</span>
                       </div>
                   </a></li>
                   <li><a rel="nofollow" href="#" class="dropdown-item d-flex">
                       <div class="msg-profile"><img src="../../../assets/img/avatar-2.jpg" alt="..."
                                                     class="img-fluid rounded-circle"></div>
                       <div class="msg-body">
                           <h3 class="h5">Frank Williams</h3><span>Sent You Message</span>
                       </div>
                   </a></li>
                   <li><a rel="nofollow" href="#" class="dropdown-item d-flex">
                       <div class="msg-profile"><img src="../../../assets/img/avatar-3.jpg" alt="..."
                                                     class="img-fluid rounded-circle"></div>
                       <div class="msg-body">
                           <h3 class="h5">Ashley Wood</h3><span>Sent You Message</span>
                       </div>
                   </a></li>
                   <li><a rel="nofollow" href="#" class="dropdown-item all-notifications text-center"> <strong>Read
                       all messages </strong></a></li>
               </ul>
           </li>-->
          <!-- Logout    -->
          <!-- (click)="collapseT() -->
          <li id="play"   class="nav-item pointer mx-2"><a class="btn btn-primary" (click)="probarSonido()"><span
                  class="d-sm-inline">Probar sonido </span><i class="fa fa-play"></i></a></li>
          <li class="nav-item pointer mx-2"><a data-toggle="modal" data-target="#myModal" lass="nav-link logout"> <span
                  class="d-none d-sm-inline">Salir </span><i class="fa fa-sign-out"></i></a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<!-- Modal-->

<div class="modal fade" id="myModal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="exampleModalLongTitle">Cerrar sesión</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ¿Estás seguro de que deseas cerrar sesión?
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-secondary">Cancelar</button>
        <button type="button" (click)="logOut()" data-dismiss="modal" class="btn btn-primary">Aceptar</button>
      </div>
    </div>
  </div>
</div>
