import {Injectable} from '@angular/core';
import {environment as environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Router} from '@angular/router';
import {UsersModel} from '../models/users.model';
import {Observable} from 'rxjs';
import { UserInterface } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserInfoService {
    token: string;
    url = environment.SERVICES_URL;


    constructor(
        public http: HttpClient,
        private router: Router,
    ) {
        this.token = sessionStorage.getItem('token');

    }

    update(id, dato: any) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.put(this.url + 'users/' + id, {
            "name": dato.name.trim(),
            "email": dato.email.trim(),
            "password": dato.password,
            "role": dato.role,
            "user_img": dato.userImg.replace(/ /g, ''),
            "id_branch_office": dato.idBranchOffice,


        }, options);
    }

    updateUser(userInterface: UserInterface) {
        return this.http.put<any>(`${ this.url }users/v2/${ userInterface._id }`, userInterface, { observe: 'response' });
    }

    show_id(id) {
        this.token = sessionStorage.getItem('token');
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get(this.url + 'users/' + id, options);
    }

    Add(dato: any) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.url + 'users', {
            "name": dato.name.trim(),
            "email": dato.email.trim(),
            "password": dato.password.trim(),
            "role": dato.role,
            "user_img": dato.userImg.replace(/ /g, ''),
            "id_branch_office": dato.idBranchOffice,
            "id_city": dato.id_city


        }, options);
    }

    createUser(userInterface: UserInterface) {
        return this.http.post<any>(`${ this.url }users`, userInterface, { observe: 'response' });
        /*let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.url + 'users', {
            "name": dato.name.trim(),
            "email": dato.email.trim(),
            "password": dato.password.trim(),
            "role": dato.role,
            "user_img": dato.userImg.replace(/ /g, ''),
            "id_branch_office": dato.idBranchOffice,
            "id_city": dato.id_city


        }, options);*/
    }

    // tslint:disable-next-line:variable-name
    getUsersByIdCity(id_city: string, page: string, queryUser?: string, role?: string) {
        let params = new HttpParams().append('page', page);
        if (queryUser) {
            params = params.append('user', queryUser);
        }
        if (role) {
            params = params.append('role', role);
        }

        return this.http.get(`${ this.url }users/city/${ id_city }`, { observe: 'response', params });
    }

    GetAll(cityId:string): Observable<any> {

        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get(this.url + 'users/city/' + cityId, options);

    }

    // tslint:disable-next-line:variable-name
    getUserById(id_user: string): Observable<any> {
        return this.http.get(`${ this.url }users/${ id_user }`, { observe: 'response' });
    }

    Get(ID): Observable<any> {

        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.get<Array<UsersModel>>(this.url + 'users/' + ID, options);

    }

    Delete(id) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);

        const options = {
            headers: httpHeaders
        };
        return request = this.http.delete(this.url + 'users/' + id, options);
    }

}
