import { Injectable } from '@angular/core';
import { compressAccurately, filetoDataURL } from 'image-conversion';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor() {
  }

  public getImagesFromFiles(fileList: File[]): Promise<{ file: File, localUrl: string, s3Url: string }[]> {
    const images: { file: File, localUrl: string, s3Url: string }[] = [];
    let fileCounter = 0;

    return new Promise((resolve, reject) => {
      for (const file of Array.from(fileList)) {

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          compressAccurately(file, 950).then(imageCompressed => {
            filetoDataURL(imageCompressed).then(localUrl => {
              images.push({
                file: new File([ imageCompressed ], file.name, { type: file.type }),
                localUrl,
                s3Url: null
              });
              fileCounter++;
              if (fileCounter === fileList.length) {
                resolve(images);
              }
            });
          });
        } else {
          fileCounter++;
          if (fileCounter === fileList.length) {
            resolve(images);
          }
        }

      }
    });
  }
}
