<div class="page login-page">
  <div class="container d-flex align-items-center">
    <div class="form-holder has-shadow">
      <div
        *ngIf="loginAlert === true"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        <strong>¡Correo inválido!</strong> Verifica de nuevo los datos.
        <button
          type="button"
          class="close"
          (click)="SetLoginTrue()"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        *ngIf="sendMessage === true"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        Hemos enviado un correo electrónico a
        <strong>{{ recoverPasswordForm.controls["email"].value }}</strong>
        para restablecer tu contraseña.
        <button
          type="button"
          class="close"
          (click)="setSendMessageFalse()"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="row">
        <!-- Logo & Information Panel-->
        <div class="col-12 col-lg-6">
          <div class="info d-flex align-items-center justify-content-center">
            <div class="text-center">
              <img class="img-logo" src="assets/img/componente_icono.svg" />
              <div class="logo">
                <h1 class="title-1">COYOTE FOODS</h1>
              </div>
              <p class="subtitle-1">Transportamos sabor</p>
            </div>
          </div>
        </div>

        <!-- Form Panel    -->
        <div class="col-lg-6 bg-white">
          <div class="form d-flex align-items-center">
            <div class="w-100">
              <form
                method="post"
                class="form-validate m-auto"
                [formGroup]="recoverPasswordForm"
              >
                <h5 class="input-material text">
                  Ingresa tu correo electrónico para recuperar tu contraseña.
                </h5>
                <div class="form-group">
                  <label for="email" class="input">Correo electrónico</label>
                  <input
                    id="email"
                    [formControl]="this.emailControl"
                    autocomplete="email"
                    [ngClass]="{
                      'box-border-red':
                        this.emailControl.invalid && this.emailControl.touched
                    }"
                    type="text"
                    name="email"
                    required
                    class="input-material input"
                  />
                </div>

                <div class="form-group">
                  <button
                    class="btn btn-primary form-control Quick-Bold"
                    [disabled]="this.emailControl.invalid"
                    (click)="recoverPassword()"
                  >
                    Recuperar contraseña
                  </button>
                  <div class="d-block mt-3">
                    <a
                      class="forgot-pass"
                      [routerLink]="[AuthRouteNames.INICIAR_SESION]"
                      >¿Quieres iniciar sesión?</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyrights text-center">
    <p>
      Designed by
      <img
        src="assets/img/logo-white-3.png"
        href="https://itelisoft.com/"
        style="width: 100px; margin-left: 5px"
        class="external"
        alt="Itelisoft Logo"
      />
    </p>
  </div>
</div>
