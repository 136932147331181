import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../../shared/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-update-password-users",
  templateUrl: "./update-password-users.component.html",
  styleUrls: ["./update-password-users.component.scss"],
})
export class UpdatePasswordUsersComponent implements OnInit {
  recoverPasswordForm = this.formBuilder.group({
    password: ["", Validators.required],
    repeatedPassword: ["", Validators.required],
  });
  error: string;
  login: boolean;
  loginAlert: boolean;
  userId: string;
  token: string;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.token = this.activatedRoute.snapshot.paramMap.get("token");
    this.userId = this.activatedRoute.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {}

  updatePassword() {
    if (
      this.recoverPasswordForm.controls["password"].value ===
      this.recoverPasswordForm.controls["repeatedPassword"].value
    ) {
      this.userService
        .updatePassword(
          this.userId,
          this.token,
          this.recoverPasswordForm.controls["password"].value.trim(),
        )
        .subscribe(
          (response: any) => {
            if (response.error) {
              Swal.fire({
                icon: "error",
                title: "¡Opps ha ocurrido un error!",
                text: "El token ha caducado, ve a la app y solicita uno nuevo.",
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "¡Contraseña actualizada!",
                text: "La contraseña ha sido actualizada con éxito, ya puedes iniciar sesión con tu nueva contraseña.",
              });
            }
          },
          () => {
            this.loginAlert = true;
          },
        );
    } else {
      this.loginAlert = true;
    }
  }

  SetLoginTrue() {
    this.loginAlert = false;
  }

  get passwordControl() {
    return this.recoverPasswordForm.get("password") as FormControl;
  }

  get repeatedPasswordControl() {
    return this.recoverPasswordForm.get("repeatedPassword") as FormControl;
  }
}
