import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment as environment} from '../../../environments/environment';
import {getCategoriesModel} from '../models/getCategories.model';
import { CategoriesInterface } from '../models/categories.model';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    token: string;
    url = environment.SERVICES_URL;
    branchId: string;

    constructor(public http: HttpClient) {
        this.branchId = sessionStorage.getItem('id_branch_office');
        this.token = sessionStorage.getItem('token');
    }

    Create(categories: any, id_BranchOffice: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders,

        };
        return request = this.http.post(this.url + 'categories', {
            "id_branch_offices": id_BranchOffice,
            "categories": categories
        }, options);
    }

    // tslint:disable-next-line:variable-name
    updateCategoriesByIdBranchOffice(id_branch_office: string, categories: Array<CategoriesInterface>) {
        return this.http.put(`${ this.url }categories/${ id_branch_office }`, categories, { observe: 'response' });
    }

    Edit(categories: any, id_BranchOffice: string, id_categorie: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.put(this.url + 'categories/' + id_categorie, {
            "id_branch_offices": id_BranchOffice,
            "categories": categories.trim()
        }, options);
    }


    GetByID(id: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.get(this.url + 'categories/branch_offices/' + id, options);
    }

    createCategories(categorie: any, idCategories: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.url + 'categories/' + idCategories, {
            "name": categorie.trim()
        }, options);
    }


    delete(idCategories: string, idCat: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.delete<Array<getCategoriesModel>>(this.url + 'categories/' + idCategories + '/' + idCat, options);
    }

    deleteFromBranchOffices(idCategories: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.delete<Array<getCategoriesModel>>(this.url + 'categories/' + idCategories, options);
    }

    createCategoriesIfDontExist(categorie: any, idBranchOffice: string) {
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.url + 'categories', {
            'id_branch_offices': idBranchOffice,
            'categories': [
                {
                    'name': categorie.trim()
                }
            ]
        }, options);
    }

    createGeneralCategory(name, image){
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.post(this.url + 'general_categories', {
            "name": name,
            "category_url":image
        }, options
        );

    }
    showAllGeneralCategory(){
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.get(this.url + 'general_categories', options);
    }
    deleteGeneralCategory(id){
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.delete(this.url + 'general_categories/' + id, options);
    }
    updateGeneralCategory(id, name, image){
        let request;
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + this.token);
        const options = {
            headers: httpHeaders
        };
        return request = this.http.put(this.url + 'general_categories/'+ id, {
            "name": name,
            "category_url":image
        }, options
        );

    }

}
