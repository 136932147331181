<div class="container-fluid">
  <div class="card">
    <div
      class="arrow-left cursor"
      [ngbTooltip]="
        this.isSuperAdmin ? 'Volver a la lista de usuarios' : 'Volver'
      "
    >
      <i
        class="fa fa-arrow-left"
        [routerLink]="this.isSuperAdmin ? ['../../'] : ['../']"
      ></i>
    </div>
    <div class="card-header text-center">
      <h4 class="Quick-Bold">
        {{ this.idUser ? "Editar usuario" : "Registrar usuario" }}
      </h4>
    </div>
    <div class="card-body">
      <form [formGroup]="this.form" autocomplete="off">
        <div class="row">
          <div class="form-group col-sm-6">
            <!-- current imagen selected -->
            <div class="row">
              <div class="col">
                <label
                  class="form-control-label Quick-Bold"
                  ngbTooltip="Se mostrará en la aplicación"
                  >Imagen de Usuario *</label
                >
                <div class="text-center mb-2" *ngIf="this.imageFile">
                  <img
                    alt="..."
                    class="main-image"
                    [src]="
                      this.imageFile.s3Url
                        ? this.imageFile.s3Url
                        : this.imageFile.localUrl
                    "
                  />
                </div>
              </div>
            </div>

            <!-- fileInput -->
            <div class="row">
              <div class="col custom-file">
                <div class="custom-file">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    class="Quick-Book custom-file-input"
                    accept="image/png, image/jpeg"
                    #fileInput
                    (change)="selectFile($event)"
                  />
                  <label class="custom-file-label" for="file"
                    >Subir imagen</label
                  >
                </div>
              </div>
              <p
                class="text-danger text-center small"
                *ngIf="this.flagFileTouched && this.imageFile === undefined"
              >
                Se necesita mínimo una imagen
              </p>
            </div>

            <!-- delete image button -->
            <div class="row text-center my-2" *ngIf="this.imageFile">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-danger Quick-Bold"
                  (click)="removeImage()"
                >
                  <i class="fas fa-trash"></i> Borrar imagen
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name" class="form-control-label Quick-Bold"
                    >Nombre *</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="form-control Quick-Book"
                    placeholder="Nombre de usuario"
                    formControlName="name"
                    #nameInput
                    [class.border-danger]="
                      this.name.touched && this.name.invalid
                    "
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="role" class="form-control-label Quick-Bold"
                    >Rol *</label
                  >
                  <select
                    (change)="eventRoleChange()"
                    name="role"
                    id="role"
                    class="form-control Quick-Book"
                    formControlName="role"
                    [class.border-danger]="
                      this.role.touched && this.role.invalid
                    "
                  >
                    <option
                      *ngFor="
                        let role of this.roleCodes
                          | keyvalue: this.Forms.keepOriginalOrder
                      "
                      [value]="role.key"
                    >
                      {{ role.value }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email" class="form-control-label Quick-Bold"
                >Correo *</label
              >
              <input
                type="text"
                name="email"
                id="email"
                class="form-control Quick-Book"
                placeholder="Correo del usuario"
                formControlName="email"
                [class.border-danger]="this.email.touched && this.email.invalid"
              />
              <small
                class="text-danger"
                *ngIf="this.email.hasError('email_duplicate')"
              >
                {{ this.email.getError("email_duplicate") }}
              </small>
            </div>

            <div class="form-group" *ngIf="this.idUser === undefined">
              <label for="password" class="form-control-label Quick-Bold"
                >Contraseña *</label
              >
              <input
                type="password"
                name="password"
                id="password"
                class="form-control Quick-Book"
                placeholder="Contraseña"
                formControlName="password"
                [class.border-danger]="
                  this.password.touched && this.password.invalid
                "
              />
            </div>

            <div
              class="form-group"
              [class.d-none]="
                this.role.value === '' ||
                this.id_branch_office.disabled ||
                !this.isSuperAdmin
              "
            >
              <label
                for="id_branch_office"
                class="form-control-label Quick-Bold"
                >Sucursal *</label
              >
              <select
                name="id_branch_office"
                id="id_branch_office"
                class="form-control Quick-Book"
                formControlName="id_branch_office"
                [class.border-danger]="
                  this.id_branch_office.touched && this.id_branch_office.invalid
                "
              >
                <option
                  *ngFor="let branchOffice of branchOfficesList"
                  [value]="branchOffice._id"
                >
                  {{ branchOffice.name }}
                </option>
              </select>
            </div>

            <div
              class="form-group"
              [class.d-none]="this.role.value === '' || this.phone.disabled"
            >
              <label for="phone" class="form-control-label Quick-Bold"
                >Teléfono *</label
              >
              <input
                type="text"
                name="phone"
                id="phone"
                class="form-control"
                maxlength="10"
                formControlName="phone"
                [class.border-danger]="this.phone.touched && this.phone.invalid"
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary Quick-Bold d-block m-auto"
          (click)="this.saveUser()"
        >
          {{ this.idUser ? "Guardar cambios" : "Registrar usuario" }}
        </button>
      </form>
    </div>
  </div>
</div>
