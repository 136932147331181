import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      req.headers.has("Authorization") ||
      req.url.includes("maps.googleapis.com")
    ) {
      return next.handle(req);
    } else {
      // console.log('Injected token');
      const httpRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      return next.handle(httpRequest);
    }
  }
}
