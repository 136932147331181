import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalCitieService } from '../../services/modal-citie.service';
import jwtDecode from 'jwt-decode';
import { debounceTime } from 'rxjs/operators';
import { Forms } from '../../Tools/forms';
import { ReviewsBranchOffices } from '../../models/reviews-branch-offices.model';
import { ReviewsBranchOfficesService } from "../../services/reviews-branch-offices.service";

@Component({
  selector: 'app-branch-office-review',
  templateUrl: './branch-office-review.component.html',
  styleUrls: [ './branch-office-review.component.scss' ]
})
export class BranchOfficeReviewComponent implements OnInit, OnDestroy {
  token: any;
  isSuperAdmin: boolean;
  Forms = Forms;
  searchReview = new Subject<string>();
  searchBranchOffice = new Subject<string>();
  reviewsCounter: number;
  reviewsList: ReviewsBranchOffices[] = [];
  currentCity: string;
  page = 0;

  valueCodes = { // backend -> frontend
    todas: 'Todas',
    1: 'Calificación 1',
    2: 'Calificación 2',
    3: 'Calificación 3',
    4: 'Calificación 4',
    5: 'Calificación 5'
  };
  reviewSearch = '';
  branchOfficeSearch = '';
  groupBranchOffices = false;
  valueSearch = Forms.getKeyByValue(this.valueCodes, this.valueCodes.todas);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: ModalCitieService,
    private reviewsBranchOfficesService: ReviewsBranchOfficesService
  ) {
  }

  ngOnInit(): void {
    this.token = jwtDecode(sessionStorage.getItem('token'));

    const userRole = sessionStorage.getItem('role');
    // TODO: USE NEXT LINE ON PRODUCTION
    // this.userRole = this.token.role;

    this.isSuperAdmin = (userRole === 'super_admin');

    const params = this.activatedRoute.snapshot.queryParams;
    if (params.nameSearch) {
      this.branchOfficeSearch = params.nameSearch;
    }
    if (params.statusSearch) {
      // this.statusSearch = params.statusSearch;
    }
    if (params.page && !isNaN(params.page)) {
      this.page = Number(params.page);
    }

    if (this.isSuperAdmin) {
      this.modalService.currentCity.subscribe((cityResponse) => {
        if (cityResponse) {
          this.currentCity = cityResponse;
          this.fetchReviews(this.page, this.page !== 0);
        }
      });
    } else {
      this.currentCity = sessionStorage.getItem('currentCityId');
      this.fetchReviews(this.page, (this.page !== 0));
    }

    this.searchReview.pipe(
      debounceTime(750)
    ).subscribe(value => {
      this.reviewSearch = value;
      this.changePage(0);
    });

    this.searchBranchOffice.pipe(
      debounceTime(750)
    ).subscribe(value => {
      this.branchOfficeSearch = (this.isSuperAdmin) ? value : '';
      this.changePage(0);
    });
  }

  ngOnDestroy(): void {
    this.searchReview.unsubscribe();
    this.searchBranchOffice.unsubscribe();
  }

  eventReviewSearch(event: KeyboardEvent) {
    this.searchReview.next((event.target as HTMLInputElement).value);
  }

  eventBranchOfficeSearch(event: KeyboardEvent) {
    this.searchBranchOffice.next((event.target as HTMLInputElement).value);
  }

  public changePage(page: number) {
    if (page !== 0) {
      const queryParams = { page };
      if (this.isSuperAdmin) {
        if (this.branchOfficeSearch) {
          Object.assign(queryParams, { branch_office: this.branchOfficeSearch });
        }
        if (this.groupBranchOffices) {
          Object.assign(queryParams, { group_branch_offices: this.groupBranchOffices });
        }
      }
      if (this.reviewSearch) {
        Object.assign(queryParams, { review_id: this.reviewSearch });
      }
      if (this.valueSearch) {
        Object.assign(queryParams, { value: this.valueSearch });
      }
      this.router.navigate([], { queryParams }).then();
    }
    this.fetchReviews(page);
  }

  public fetchReviews(page: number, keepPage?: boolean): void {
    this.spinner.show();
    this.reviewsList = [];

    this.reviewsBranchOfficesService.getReviewsByIdCity(
      this.currentCity,
      (keepPage) ? '0' : page.toString(),
      this.reviewSearch,
      (this.isSuperAdmin) ? this.branchOfficeSearch : sessionStorage.getItem('id_branch_office'),
      this.valueSearch, String(this.groupBranchOffices)
    ).subscribe((reviewsResponse: any) => {
      if (Forms.handleData(reviewsResponse, 200)) {
        if (page === 0 || keepPage) {
          this.reviewsCounter = reviewsResponse.body;
          if (keepPage) {
            this.fetchReviews(this.page);
          } else {
            this.changePage(1);
          }
        } else {
          this.reviewsList = reviewsResponse.body;
          this.page = page;
        }
      } else {
        this.reviewsCounter = undefined;
        this.reviewsList = [];
      }
    }, error => {
      Forms.handleError(error);
      this.spinner.hide();
    }, () => this.spinner.hide());
  }

}
